import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Sidebar from "../Components/SideBar";

function PaymentManage() {
  const [searchInput, setSearchInput] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);

  // Hàm tìm kiếm các thanh toán
  const searchPayments = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  // Hàm mở rộng thông tin chi tiết khi nhấn vào dòng
  const toggleDetail = (transactionId) => {
    setExpandedRow(expandedRow === transactionId ? null : transactionId);
  };

  // Fetch QR Code when payment changes
  useEffect(() => {
    if (!expandedRow) return;

    const paymentId = expandedRow; // Using the expandedRow as paymentId
    fetch(`http://localhost:8000/api/payment/${paymentId}/qrcode`)
      .then((response) => response.json())
      .then((data) => setQrCodeUrl(data.qr_code_url))
      .catch((error) => console.error("Error fetching QR code:", error));
  }, [expandedRow]);

  // Lọc danh sách giao dịch theo ô tìm kiếm
  const filteredTransactions = transactions.filter((transaction) => {
    const { ma_giao_dich, user } = transaction;
    return (
      ma_giao_dich.toLowerCase().includes(searchInput) ||
      (user?.ten && user.ten.toLowerCase().includes(searchInput))
    );
  });

  // Lấy dữ liệu giao dịch từ API
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        // Lấy token từ localStorage hoặc sessionStorage
        const token = localStorage.getItem("access_token");

        // Gửi yêu cầu với Authorization header
        const response = await fetch("http://localhost:8000/api/ThanhtoanAll", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,  // Gắn token vào header
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTransactions(data.data);
        } else {
          console.error("Lỗi khi lấy dữ liệu giao dịch:", response.statusText);
        }
      } catch (error) {
        console.error("Lỗi khi lấy dữ liệu giao dịch:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []); 

  if (loading) {
    return <div>Đang tải...</div>;
  }

  return (
    <div className="bg-gray-100">
      <div className="flex">
        <Sidebar />
        <div className="flex-1 flex flex-col">
       <Header/>
          <main className="flex-1 p-6">
            <div className="flex items-center mb-6">
              <input
                type="text"
                id="searchInput"
                placeholder="Tìm kiếm theo mã giao dịch hoặc tên sinh viên..."
                className="border border-gray-300 p-3 rounded-md w-full mr-4 shadow-sm focus:outline-none focus:ring focus:ring-indigo-500"
                onChange={searchPayments}
              />
            </div>

            <div className="overflow-x-auto mb-6">
              <table
                className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md"
                id="paymentTableBody"
              >
                <thead className="bg-gray-200">
                  <tr>
                    <th className="py-3 px-4 border-b text-left">
                      Mã Giao Dịch
                    </th>
                    <th className="py-3 px-4 border-b text-left">
                      Tên Sinh Viên
                    </th>
                    <th className="py-3 px-4 border-b text-left">Tuyến Xe</th>
                    <th className="py-3 px-4 border-b text-center">Số Tiền</th>
                    <th className="py-3 px-4 border-b text-left">Trạng Thái</th>
                    <th className="py-3 px-4 border-b text-left">Ví Điện Tử</th>
                    <th className="py-3 px-4 border-b text-center">
                      Hành Động
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTransactions.map((transaction) => {
                    const ticketCount = transaction.ve.length; // Số vé
                    const userName =
                      ticketCount > 0 &&
                      transaction.ve[0].user &&
                      transaction.ve[0].user.ten
                        ? transaction.ve[0].user.ten
                        : "Chưa có tên";

                    return (
                      <React.Fragment key={transaction.ma_giao_dich}>
                        <tr
                          className="hover:bg-gray-100"
                          onClick={() => toggleDetail(transaction.ma_giao_dich)}
                        >
                          <td className="py-3 px-4 border-b text-left">
                            {transaction.ma_giao_dich}
                          </td>
                          <td className="py-3 px-4 border-b text-left">
                            {userName}
                          </td>
                          <td className="py-3 px-4 border-b text-left">
                            {transaction.ve.length > 0 &&
                            transaction.ve[0].chuyendi
                              ? `Tuyến ${transaction.ve[0].chuyendi.tenchuyen}`
                              : "Chưa có tuyến"}
                          </td>
                          <td className="py-3 px-4 border-b text-center">
                            {new Intl.NumberFormat("vi-VN", {
                              style: "currency",
                              currency: "VND",
                            }).format(transaction.so_tien)}
                          </td>
                          <td className="py-3 px-4 border-b text-left">
                            {transaction.trang_thai === 1 ? (
                              <span className="text-green-500">
                                Đã thanh toán
                              </span>
                            ) : transaction.trang_thai === 2 ? (
                              <span className="text-red-500">
                                Đã hủy thanh toán
                              </span>
                            ) : transaction.trang_thai === 0 ? (
                              <span className="text-yellow-500">
                                Chờ thanh toán
                              </span>
                            ) : (
                              <span className="text-gray-500">
                                Trạng thái không xác định
                              </span>
                            )}{" "}
                            {/* Xử lý giá trị không xác định */}
                          </td>
                          <td className="py-3 px-4 border-b text-left">
                            {transaction.phuong_thuc}
                          </td>
                          <td className="py-3 px-4 border-b text-center">
                            <button className="bg-blue-500 text-white py-1 px-3 rounded-md">
                              Xem Chi Tiết
                            </button>
                          </td>
                        </tr>
                        {expandedRow === transaction.ma_giao_dich && (
                          <tr id={`detail-${transaction.ma_giao_dich}`}>
                            <td colSpan="7" className="py-3 px-4 border-b">
                              <div className="flex p-4 bg-gray-100">
                                <div className="flex-1">
                                  <p>
                                    <strong>Tên Sinh Viên:</strong> {userName}
                                  </p>
                                  <p>
                                    <strong>Mã Vé Đã Đặt:</strong> {ticketCount}
                                  </p>
                                  <p>
                                    <strong>Tổng Số Tiền:</strong>
                                    {new Intl.NumberFormat("vi-VN", {
                                      style: "currency",
                                      currency: "VND",
                                    }).format(transaction.so_tien)}
                                  </p>

                                  <p>
                                    <strong>Trạng Thái Thanh Toán:</strong>{" "}
                                    {transaction.trang_thai === 1
                                      ? "Đã thanh toán"
                                      : "Chờ thanh toán"}
                                  </p>
                                  <p>
                                    <strong>Ngày Thanh Toán:</strong>{" "}
                                    {transaction.ngay_thanh_toan}
                                  </p>
                                  <p>
                                    <strong>Ví Điện Tử:</strong>{" "}
                                    {transaction.phuong_thuc}
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default PaymentManage;
