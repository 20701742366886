import React, { useState, useEffect } from "react";
import Header from "../Components/Header";

import { useSnackbar } from "notistack";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Components/SideBar";
const AdminProfile = () => {
  const [user, setUser] = useState({
    id:"",
    img: "",
    ten: "",
    email: "",
    sdt: "",
    vaitro: "",
  });

  const roles = {
    1: "Admin",
    2: "Moderator",
    3: "User",
  };
  
  const roleToValue = {
    Admin: 1,
    Moderator: 2,
    User: 3,
  };
  
  const [imagePreview, setImagePreview] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  // Fetch admin profile
  useEffect(() => {
    const fetchAdminProfile = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/user", {
          method: "GET", 
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setUser({
          id: data.user.id || "",
          img: data.user.img || "",
          ten: data.user.ten || "",
          email: data.user.email || "",
          sdt: data.user.sdt || "",
          vaitro: roles[data.user.vaitro] || "",
        });
      } catch (error) {
        console.error("Error fetching admin profile:", error);
        enqueueSnackbar("Không thể tải dữ liệu admin", { variant: "error" });
      }
    };

    fetchAdminProfile();
  }, [token]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUser({ ...user, img: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSaveProfile = async () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      console.error("Token không tồn tại hoặc đã hết hạn");
      return;
    }
  
    const formData = new FormData();
    formData.append("img", user.img);
    formData.append("ten", user.ten);
    formData.append("sdt", user.sdt);
    formData.append("email", user.email);
    // formData.append("mssv", user.mssv);
    // formData.append("diachi", user.diachi);
  
    try {
      const response = await fetch(`http://localhost:8000/api/user/${user.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData, // Gửi formData thay vì JSON
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Cập nhật thất bại");
      }
  
      const data = await response.json();
      enqueueSnackbar('Cập nhật thông tin thành công!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 2000,
      });
  
      setUser({ ...user, img: data.user.img }); // Cập nhật lại ảnh nếu cần
    } catch (error) {
      console.error("Lỗi khi cập nhật thông tin:", error.message);
      alert(`Cập nhật thất bại: ${error.message}`);
    }
  };
  

  return (
    <>
      <div className="flex h-screen">
  <Sidebar />
  <div className="flex-1 flex flex-col">
    <Header />
      <div className="bg-gray-900 text-white overflow-y-auto">
        <div className="container mx-auto">
          <form
            // onSubmit={handleSubmit}
            className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-4xl mx-auto"
          >
            {/* Profile Image */}
            <div className="relative">
                <img
                  src={user.img}
                  alt="Profile"
                  className="w-32 h-32 rounded-full object-cover border-2 border-gray-200"
                />
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <label className="block text-sm font-medium text-gray-700">
                    Chọn ảnh mới
                  </label>
                  <input
                    type="file"
                    accept="storage/uploads/*"
                    onChange={handleImageChange}
                    className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-green-50 file:text-green-700
                    hover:file:bg-green-100"
                  />
                  {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="mt-4 w-24 h-24 object-cover rounded-full"
                />
              )}
                </div>

            {/* Name */}
            <div className="mb-6">
              <label htmlFor="name" className="block text-gray-300 font-semibold">
                Tên
              </label>
              <input
                type="text"
                id="name"
                name="ten"
                value={user.ten}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg bg-gray-700 border border-gray-600 focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            {/* Email */}
            <div className="mb-6">
              <label htmlFor="email" className="block text-gray-300 font-semibold">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={user.email}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg bg-gray-700 border border-gray-600 focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            {/* Phone */}
            <div className="mb-6">
              <label htmlFor="phone" className="block text-gray-300 font-semibold">
                Số điện thoại
              </label>
              <input
                type="tel"
                id="phone"
                name="sdt"
                value={user.sdt}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg bg-gray-700 border border-gray-600 focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            {/* Role */}
            <div className="mb-6">
              <label htmlFor="role" className="block text-gray-300 font-semibold">
                Quyền
              </label>
              <select
                id="role"
                name="vaitro"
                value={user.vaitro}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg bg-gray-700 border border-gray-600 focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="Admin">Admin</option>
                <option value="Moderator">Moderator</option>
                <option value="User">User</option>
              </select>
            </div>

            {/* Update Button */}
            <div className="mt-8">
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault(); // Ngăn chặn reload trang
                  handleSaveProfile(); }}
                className="bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-6 rounded-lg transition duration-300"
              >
                Cập nhật hồ sơ
              </button>
              {/* Back Button */}
              <button
                type="button"
                onClick={() => navigate("/admin")}
                className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-6 rounded-lg transition duration-300"
              >
                Quay lại
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>
      </div>
      <Footer/>
    </>
  );
};

export default AdminProfile;
