import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const BusList = () => {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the trips data from your Laravel API
    axios
      .get('http://localhost:8000/api/trips')
      .then((response) => {
        setTrips(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the trips!', error);
        setLoading(false);
      });
  }, []);


  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <section className="bus-list w-full max-w-[2000px] bg-gradient-to-br from-white to-gray-200 p-6 md:p-12 shadow-2xl rounded-lg">
        <h2 className="text-center text-4xl mb-12 text-gray-800">Danh sách xe buýt tại BusFree</h2>
        
        {/* List View for mobile */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {trips.map((trip) => (
            <div key={trip.idchuyen} className="card bg-white shadow-md rounded-xl p-4 transition-transform duration-300 hover:scale-105">
              {/* Card Content */}
              <div className="flex flex-col items-center">
                <img
                  src={trip.chitietxebuss?.image}
                  alt={trip.chitietxebuss?.tenxe}
                  className="w-full h-40 object-cover rounded-lg border-2 border-gray-200 shadow-lg mb-4"
                />
                <h3 className="text-xl font-semibold text-center text-green-600">{trip.chitietxebuss?.tenxe}</h3>
                <p className="text-sm text-gray-600">Biển số xe: {trip.chitietxebuss?.biensoxe}</p>
                <p className="text-sm text-gray-600">Địa điểm đến: {trip.chitiettuyenduong?.noixuatphat}</p>
                <p className="text-sm text-gray-600">Địa điểm kết thúc: {trip.chitiettuyenduong?.noiden}</p>
              </div>

              {/* Action Button */}
              <div className="mt-4 text-center">
                <Link to={`/trips/${trip.idchuyen}`}>
                  <button
                    class="text-xl w-32 h-12 rounded bg-emerald-500 text-white relative overflow-hidden group z-10 hover:text-white duration-1000"
                  >
                    <span
                      class="absolute bg-emerald-600 w-36 h-36 rounded-full group-hover:scale-100 scale-0 -z-10 -left-2 -top-10 group-hover:duration-500 duration-700 origin-center transform transition-all"
                    ></span>
                    <span
                      class="absolute bg-emerald-800 w-36 h-36 -left-2 -top-10 rounded-full group-hover:scale-100 scale-0 -z-10 group-hover:duration-700 duration-500 origin-center transform transition-all"
                    ></span>
                    Chi tiết
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default BusList;
