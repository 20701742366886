
import React, { useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
const Banner = () => {
  const [departure, setDeparture] = useState("");
  const [destination, setDestination] = useState("");
  const [date, setDate] = useState("");
  const [error, setError] = useState("");
  const [busDetails, setBusDetails] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    setHasSearched(true);

    

    const url = `http://localhost:8000/api/tripssearch?departure=${departure}&destination=${destination}&date=${date}`;

    try {
      const response = await fetch(url);
    
      if (response.ok) {
        const data = await response.json();
        setError("");
    
        if (data.length > 0) {
          // Lấy tối đa 10 `tuyen_ids`
          const tuyenIds = data.slice(0, 10).map((item) => item.id).join(",");
    
          // Gửi yêu cầu để lấy chi tiết 10 chuyến xe
          const busDetailsResponse = await fetch(
            `http://localhost:8000/api/tripsgetTripsByTuyen?tuyen_ids=${tuyenIds}`
          );
    
          if (busDetailsResponse.ok) {
            const busData = await busDetailsResponse.json();
    
            // Giới hạn dữ liệu trả về chỉ còn 10 kết quả (nếu cần thiết)
            const limitedBusData = busData.slice(0, 10);
    
            setBusDetails(limitedBusData); // Lưu vào state
          } else {
            setError("Không tìm thấy chi tiết chuyến xe.");
          }
        } else {
          setError("Không tìm thấy tuyến đường nào.");
        }
      } else {
        setError("Không tìm thấy tuyến đường nào.");
      }
    } catch (err) {
      setError("Lỗi kết nối với máy chủ.");
    }
    
    
  };
  function formatDate(date) {
    if (!date) return "N/A";
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return (
    <section
    className="hero bg-cover bg-center text-center text-gray-800 h-[600px] sm:h-[800px] flex flex-col justify-center items-center relative"
    style={{ backgroundImage: "url('/images/Web banner Vinbus 2-01.png')" }}
  >
    <div className="banner-content inset-0 flex flex-col justify-center items-center z-10">
      <h1 className="text-4xl sm:text-7xl mb-5 text-white">Đặt vé xe buýt cho sinh viên</h1>
      <p className="text-xl sm:text-3xl mb-8 text-white">
        Giúp bạn tiết kiệm thời gian, dễ dàng di chuyển!
      </p>
  
      <form
        onSubmit={handleSearch}
        className="search-form flex flex-col sm:flex-row justify-center gap-4 sm:gap-0 mt-5 z-10 relative"
      >
        {/* Input for departure */}
        <div className="input-container relative w-full sm:w-[260px] mb-4 sm:mb-0">
          <i className="fas fa-bus absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 text-lg"></i>
          <input
            type="text"
            placeholder="Nhập điểm đi..."
            value={departure}
            onChange={(e) => setDeparture(e.target.value)}
            className="input-location border border-gray-300 shadow-sm rounded-l-full py-5 pl-14 pr-5 w-full focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>
  
        {/* Input for destination */}
        <div className="input-container relative w-full sm:w-[260px] mb-4 sm:mb-0">
          <i className="fas fa-map-marker-alt absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 text-lg"></i>
          <input
            type="text"
            placeholder="Nhập điểm đến..."
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            className="input-location border border-gray-300 shadow-sm py-5 pl-14 pr-20 w-full focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>
  
        {/* Input for date */}
        <div className="input-container relative w-full sm:w-[200px] mb-4 sm:mb-0">
          <i className="fas fa-calendar-alt absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-lg"></i>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="input-date border border-gray-300 shadow-sm py-5 pl-10 pr-25 w-full focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>
  
        {/* Submit button */}
        <button
          type="submit"
          className="search-button bg-green-500 text-white rounded-r-full py-3 px-5 flex items-center gap-2 hover:bg-green-600 transition-colors duration-300 w-full sm:w-auto"
        >
          <i className="fas fa-search text-lg"></i> Tìm vé
        </button>
      </form>
  
      {error && <p className="text-red-500 mt-4">{error}</p>}
  
      {/* Bus details section */}
      {hasSearched && busDetails.length > 0 && (
        <div className="mt-8 w-full max-w-[1000px] mx-auto p-6 bg-white rounded-xl shadow-lg relative">
          <button
            onClick={() => {
              setHasSearched(false);
              setBusDetails([]);
            }}
            className="absolute top-4 right-4 bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600"
          >
            X
          </button>
  
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4">Chi tiết các chuyến xe</h2>
          <div className="bus-list max-h-[300px] overflow-y-auto p-2 space-y-4">
            {busDetails.map((bus, index) => (
              <div
                key={index}
                className="flex flex-col sm:flex-row items-start sm:items-center bg-gray-100 p-4 rounded-lg shadow-md w-full max-w-[900px]"
              >
                <img
                  src={bus.buss.img || "/images/default_bus.png"}
                  alt={bus.tenxe || "Xe buýt"}
                  className="w-24 h-24 object-cover rounded-md mb-4 sm:mb-0 sm:mr-4"
                  onError={(e) => (e.target.src = "/images/default_bus.png")}
                />
                <div className="flex flex-col text-sm sm:text-base w-full">
                  <div className="flex justify-between mb-2">
                    <p className="font-semibold">Chuyến đi:</p>
                    <p>{bus.tenchuyen || "N/A"}</p>
                  </div>
                  <div className="flex justify-between mb-2">
                    <p className="font-semibold">Tên xe:</p>
                    <p>{bus.buss.tenxe || "N/A"}</p>
                  </div>
                  <div className="flex justify-between mb-2">
                    <p className="font-semibold">Số ghế:</p>
                    <p>{bus.buss.sochongoi || "N/A"}</p>
                  </div>
                  <div className="flex justify-between mb-2">
                    <p className="font-semibold">Biển số xe:</p>
                    <p>{bus.buss.biensoxe || "N/A"}</p>
                  </div>
                  <div className="flex justify-between mb-2">
                    <p className="font-semibold">Giá chuyến:</p>
                    <p>{bus.giachuyen ? `${bus.giachuyen} VND` : "N/A"}</p>
                  </div>
                  <div className="flex justify-between mb-2">
                    <p className="font-semibold">Ngày khởi hành:</p>
                    <p>{bus.created_at ? formatDate(bus.created_at) : "N/A"}</p>
                  </div>
                </div>
                <button
                  className="mt-4 sm:mt-0 sm:ml-auto bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition-colors"
                  onClick={() => window.location.href = `/trips/${bus.id}`}
                >
                  Xem chi tiết
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  </section>
  
  
  
  );
};

export default Banner;
