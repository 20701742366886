
import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { error } from "jquery";
import { useSnackbar } from "notistack";
import axios from "axios";
const StudentProfile = () => {
  const [user, setUser] = useState({
    img: "",
    id: "",
    ten: "",
    sdt: "",
    email: "",
    mssv: "",
    diachi: "",
  });

  const [bookingHistory, setBookingHistory] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const token = localStorage.getItem("access_token"); // Replace with actual authentication token

  // Fetch user profile
  useEffect(() => {
    fetch("http://localhost:8000/api/user", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Response status:", response.status); // Kiểm tra mã trạng thái
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("User data:", data); // Kiểm tra dữ liệu user
        // Cập nhật dữ liệu người dùng nếu cấu trúc khớp
        setUser({
          img: data.user.img || "",
          id: data.user.id || "",
          ten: data.user.ten || "",
          sdt: data.user.sdt || "",
          email: data.user.email || "",
          mssv: data.user.mssv || "",
          diachi: data.user.diachi || "",
        });
      })
      .catch((error) => console.error("Error fetching profile:", error));
  }, [token]);
  const [imagePreview, setImagePreview] = useState(null);
  // Handle input change for user information
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Updating ${name} to ${value}`); // Log để kiểm tra
    setUser({ ...user, [name]: value });
    if (name === "img") {
      console.log("Updated Image URL:", value);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUser({ ...user, img: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSaveProfile = async () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      console.error("Token không tồn tại hoặc đã hết hạn");
      return;
    }
  
    const formData = new FormData();
    
    // Chỉ gửi ảnh mới nếu người dùng thay đổi ảnh
    if (user.img && typeof user.img !== "string") { // Kiểm tra xem người dùng có thay đổi ảnh hay không
      formData.append("img", user.img);
    }
  
    // Gửi các trường thông tin khác dù có thay đổi ảnh hay không
    formData.append("ten", user.ten);
    formData.append("sdt", user.sdt);
    formData.append("email", user.email);
    formData.append("mssv", user.mssv);
    formData.append("diachi", user.diachi);
  
    try {
      const response = await fetch(`http://localhost:8000/api/user/${user.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData, // Gửi formData thay vì JSON
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Cập nhật thất bại");
      }
  
      const data = await response.json();
      enqueueSnackbar('Cập nhật thông tin thành công!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 2000,
      });
  
      setUser({ ...user, img: data.user.img || user.img }); // Cập nhật lại ảnh nếu cần
    } catch (error) {
      console.error("Lỗi khi cập nhật thông tin:", error.message);
      alert(`Cập nhật thất bại: ${error.message}`);
    }
  };
  
  

  useEffect(() => {
    if (!user.id) return;
    axios
      .get(`http://localhost:8000/api/ve/history/${user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Booking History Data:", response.data.data); // Kiểm tra dữ liệu trả về
        setBookingHistory(response.data.data); // Đảm bảo response.data là mảng
      })
      .catch((error) => {
        enqueueSnackbar("Lỗi khi tải lịch sử đặt vé", { variant: "error" });
        console.error("Error fetching booking history:", error);
      });
  }, [user.id, token]);
  
  

  console.log(user.img);  // Kiểm tra giá trị user.img
  console.log(imagePreview);  // Kiểm tra giá trị imagePreview
  return (
    <>
    <Header />
    <div className="bg-gray-100">
      <div className="max-w-5xl mx-auto mt-10 mb-10 bg-white p-6 rounded-lg shadow-lg">
        {/* Personal Information */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Thông tin cá nhân</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="flex items-center justify-center sm:justify-start">
              <div className="relative">
                <img
                  src={user.img}
                  alt="Profile"
                  className="w-32 h-32 rounded-full object-cover border-2 border-gray-200"
                />
              </div>
              <div className="flex flex-col space-y-2 w-full sm:ml-6">
                <label className="block text-sm font-medium text-gray-700">
                  Chọn ảnh mới
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-green-50 file:text-green-700
                  hover:file:bg-green-100"
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="mt-4 w-24 h-24 object-cover rounded-full"
                  />
                )}
              </div>
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700">Mã sinh viên</label>
              <input
                type="text"
                name="mssv"
                value={user.mssv}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Họ và tên</label>
              <input
                type="text"
                name="ten"
                value={user.ten}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={user.email}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Số điện thoại</label>
              <input
                type="text"
                name="sdt"
                value={user.sdt}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Địa chỉ</label>
              <input
                type="text"
                name="diachi"
                value={user.diachi}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
          </div>
          <div className="mt-4">
            <button
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-orange-600"
              onClick={handleSaveProfile}
            >
              Lưu thay đổi
            </button>
          </div>
        </section>
  {/* Booking History */}
<section>
  <h2 className="mt-8 text-3xl font-semibold text-gray-800">Lịch sử đặt vé</h2>
  <div className="mt-6 space-y-6">
    {Array.isArray(bookingHistory) && bookingHistory.map((booking, index) => (
      <div 
        key={booking.id} 
        className={`bg-white shadow-xl rounded-xl p-6 transition-all ease-in-out duration-300 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"} hover:bg-green-100 hover:shadow-lg`}>
        
        <div className="flex items-center justify-between mb-4">
          {/* Logo or Icon */}
          <div className="flex items-center space-x-2">
            <div>
              <span className="text-lg font-semibold text-gray-800">{booking.tenchuyen}</span>
            </div>
          </div>

          {/* Date */}
          <div className="text-sm text-gray-600">{booking.ngaythanhtoan}</div>
        </div>

        <div className="border-t border-gray-200 my-4"></div>

        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Nơi xuất phát</span>
            <span className="text-sm text-gray-800 font-medium">{booking.noixuatphat}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Nơi đến</span>
            <span className="text-sm text-gray-800 font-medium">{booking.noiden}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Giá chuyến</span>
            <span className="text-sm text-gray-800 font-medium">{booking.giachuyen} VNĐ</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Thời gian</span>
            <span className="text-sm text-gray-800 font-medium">{booking.thoigianbatdau}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Phương thức thanh toán</span>
            <span className="text-sm text-gray-800 font-medium">{booking.phuongthucthanhtoan}</span>
          </div>

          <div className="border-t border-gray-200 my-4"></div>

          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-700">Trạng thái thanh toán</span>
            <span className={`text-sm font-medium ${booking.trangthaithanhtoan === "Đã thanh toán" ? "text-green-600" : "text-red-500"}`}>
              {booking.trangthaithanhtoan}
            </span>
          </div>
        </div>

        {/* Footer or Action */}
        <div className="flex justify-between items-center mt-4">
          <div className="text-sm text-gray-500">{booking.id}</div>
        </div>
      </div>
    ))}
  </div>
</section>



      </div>
      <Footer />
    </div>
  </>
  
  );
};

export default StudentProfile;
