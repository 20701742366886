import "@fortawesome/fontawesome-free/css/all.min.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AccountDropdown from "../Components/AccountDropdown";

const Header = () => {
  const userEmail = localStorage.getItem("user_email");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle mobile menu
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Close menu if clicking outside
  const closeMenu = (e) => {
    if (!e.target.closest(".sidebar") && !e.target.closest(".menu-toggle")) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", closeMenu);
    } else {
      document.removeEventListener("click", closeMenu);
    }
    return () => document.removeEventListener("click", closeMenu);
  }, [isMenuOpen]);

  return (
    <header className="bg-gradient-to-r from-red-500 to-green-500 sticky top-0 z-50">
      {/* Tuyết đọng phía trên Header */}
      <div className="w-full bg-white h-2 relative before:absolute before:content-[''] before:w-full before:h-1 before:bg-gray-300 before:rounded-full before:top-1"></div>

      {/* Navigation */}
      <nav className="flex items-center justify-between w-full px-16 py-2">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            src="/images/logo.png"
            alt="BusFree Logo"
            className="h-16 w-auto"
          />
        </Link>

        {/* Seasonal Icons */}
        <div className="hidden lg:flex items-center space-x-4 text-white text-xl">
          <i className="fas fa-tree animate-bounce"></i>
          <i className="fas fa-sleigh animate-pulse"></i>
          <i className="fas fa-gift animate-spin"></i>
        </div>

        {/* Mobile Menu */}
        <button
          onClick={toggleMenu}
          className="lg:hidden text-white text-2xl ml-auto menu-toggle"
        >
          <i className={`fas ${isMenuOpen ? "fa-times" : "fa-bars"}`} />
        </button>

        {/* Sidebar Menu for Mobile */}
        <div
          className={`fixed top-0 right-0 w-64 h-full bg-gradient-to-r from-red-500 to-green-500 z-50 transform ${
            isMenuOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 lg:hidden sidebar`}
        >
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-white text-3xl"
          >
            <i className="fas fa-times" />
          </button>

          <ul className="mt-16 flex flex-col space-y-6 p-6">
            <li className="flex items-center gap-x-3">
              <i className="fas fa-home text-xl text-white"></i>
              <Link
                to="/"
                className="text-white text-lg hover:text-yellow-300 transition-colors duration-300"
              >
                Trang chủ
              </Link>
            </li>
            <li className="flex items-center gap-x-3">
              <i className="fas fa-bus text-xl text-white"></i>
              <Link
                to="/route"
                className="text-white text-lg hover:text-yellow-300 transition-colors duration-300"
              >
                Tuyến Xe
              </Link>
            </li>
            <li className="flex items-center gap-x-3">
              <i className="fas fa-info-circle text-xl text-white"></i>
              <Link
                to="/about"
                className="text-white text-lg hover:text-yellow-300 transition-colors duration-300"
              >
                Giới thiệu
              </Link>
            </li>
            <li className="flex items-center gap-x-3">
              <i className="fas fa-envelope text-xl text-white"></i>
              <Link
                to="/contact"
                className="text-white text-lg hover:text-yellow-300 transition-colors duration-300"
              >
                Hỗ trợ
              </Link>
            </li>
            <li className="flex items-center gap-x-3">
              <i className="fas fa-newspaper text-xl text-white"></i>
              <Link
                to="/news"
                className="text-white text-lg hover:text-yellow-300 transition-colors duration-300"
              >
                Tin tức
              </Link>
            </li>
            <li className="flex items-center gap-x-3">
              <i className="fas fa-search text-xl text-white"></i>
              <Link
                to="/timkiem"
                className="text-white text-lg hover:text-yellow-300 transition-colors duration-300"
              >
                Tìm Kiếm
              </Link>
            </li>
            <li className="flex items-center gap-x-3">
              <AccountDropdown userName={userEmail} />
            </li>
          </ul>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex space-x-8 ml-auto">
          <li>
            <Link
              to="/"
              className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300"
            >
              <i className="fas fa-home text-lg mr-1 px-3 text-2xl"></i> Trang
              chủ
            </Link>
          </li>
          <li>
            <Link
              to="/route"
              className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300"
            >
              <i className="fas fa-bus text-lg mr-1"></i> Tuyến Xe
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300"
            >
              <i className="fas fa-info-circle text-lg mr-1"></i> Giới thiệu
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300"
            >
              <i className="fas fa-envelope text-lg mr-1"></i> Hỗ trợ
            </Link>
          </li>
          <li>
            <Link
              to="/news"
              className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300"
            >
              <i className="fas fa-newspaper text-lg mr-1"></i> Tin tức
            </Link>
          </li>
          <li>
            <Link
              to="/timkiem"
              className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300"
            >
              <i className="fas fa-search text-lg mr-1"></i> Tìm Kiếm
            </Link>
          </li>
          <AccountDropdown userName={userEmail} />
        </ul>
      </nav>
    </header>
  );
};

export default Header;
