import './App.css';
import BusListPage from './Page/ListBusPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TrangChu from './Page/Trangchu';
import DetailBus from './Page/DetailBus';
import Login from './Page/Login';
import Register from './Page/Register';
import { GoogleOAuthProvider } from '@react-oauth/google'; 
import AboutBusFree from './Page/About';
import CustomerSupport from './Page/Contact';
import ThanhToan from './Page/Thanhtoan';
import Profile from './Page/Profile';
import AdminDashboard from './Admin/Page/AdminDashboard';
import UserManagement from './Admin/Page/UserManagement';
import RouteManage from './Admin/Page/RouteManage';
import TicketManagement from './Admin/Page/TicketManage';
import PaymentManage from './Admin/Page/PaymentManage';
import CommentManagement from './Admin/Page/CommentManage';
import ProfileAdmin from './Admin/Page/ProfileAdmin';
import ForgotPassword from './Page/ForegetPass';
import ResetPassword from './Page/ResetPassword';
import TripsManagement from './Admin/Page/TripManage';
import CateManage from './Admin/Page/CateManage';
import BusManagement from './Admin/Page/BusManagement';
import MaQRPage from './Page/MaQRPage';
import ThanhToanThanhCong from './Page/thanhtoanthanhcong';
import BusList from './Components/BusList';
import CommentsForm from './Components/CommentsForm';
import PaymentResult from './Components/PaymentResult';
import NewsPage from './Page/NewsPage';
import PostManagement from './Admin/Page/PostManagement';
import Mapp from './Components/timkiem';
import NewsDetail from './Page/DetailNews';
import ProtectedRoute from './Components/ProtectedRoute';
import NotAuthorized from './Page/NotAuthorized';
import CKE from './Components/CKE';

function App() {
  return (
    <>
    <GoogleOAuthProvider clientId="260464850273-cqq92h183m8qfg60ooi0mqcbe5ibni0d.apps.googleusercontent.com">
    <Router>
      {/* <Header/> */}
            <Routes>
                <Route path="/" element={<TrangChu/>} />
                <Route path="/route" element={<BusListPage/>} />
                <Route path="/buslist" element={<BusList />} />
                <Route path="/trips/:id" element={<DetailBus />} />
                <Route path="/login" element={<Login/>} />
                <Route path="/register" element={<Register/>} />
                <Route path="/about" element={<AboutBusFree/>} />
                <Route path="/contact" element={<CustomerSupport/>} />
                <Route path="/pay" element={<ThanhToan/>} />
                <Route path="/profile" element={<Profile/>} />
                <Route path="/not-authorized" element={<NotAuthorized />} />
                <Route element={<ProtectedRoute roleRequired="1" />}>
                <Route path="/admin" element={<AdminDashboard />} />
                <Route path="/Admin/Dashboard" element={<AdminDashboard/>} />
                <Route path="/Admin/qlNguoidung" element={<UserManagement/>} />
                <Route path="/Admin/qlTuyenduong" element={<RouteManage/>} />
                <Route path="/Admin/qlDatVe" element={<TicketManagement/>} />
                <Route path="/Admin/qlThanhtoan" element={<PaymentManage/>} />
                <Route path="/Admin/qlBinhluan" element={<CommentManagement/>} />
                <Route path="/Admin/profileAdmin" element={<ProfileAdmin/>} />
                <Route path="/Admin/qltrips" element={<TripsManagement/>} />
                <Route path="/Admin/qlBus" element={<BusManagement/>} />
                <Route path="/Admin/qlDM" element={<CateManage/>} />
                <Route path="/Admin/qlPost" element={<PostManagement/>} />
              </Route>
                <Route path="/forgot-password" element={<ForgotPassword/>} />
                <Route path="/reset-password" element={<ResetPassword/>} />
                <Route path="/QR" element={<MaQRPage />} />
                <Route path="/thanh-toan-thanh-cong" element={<ThanhToanThanhCong />} />
                <Route path="/ratingsGet/:id" component={CommentsForm} />
                <Route path="/payment-result" element={<PaymentResult />} />
                <Route path="/news" element={<NewsPage />} />
                <Route path="/news/:id" element={<NewsDetail />} />
                <Route path="/timkiem" element={<Mapp />} />
                <Route path="/details/news" element={<NewsDetail/>} />
                <Route path="/CKE" element={<CKE/>}/>
            </Routes>
        </Router>
        </GoogleOAuthProvider>
    </>
  );
}

export default App;
