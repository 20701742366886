import React, { useState } from "react";
import Banner from "../Components/Banner";
import BannerHome from "../Components/BannerHome";
import BusList from "../Components/BusList";
import ChatBot from "../Components/ChatAI";
import ColorfulBanner from "../Components/ColorFullBanner";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import NewsHome from "../Components/NewHome";
import Partners from "../Components/Partners";
import SupportForm from "../Components/SupportForm";
import ScrollToTop from "../Components/ScrollToTop";
import Snowfall from "react-snowfall"; // Import thư viện tuyết rơi
import { GoogleOAuthProvider } from "@react-oauth/google";

const TrangChu = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  // Toggle mở/đóng chatbot
  const toggleChat = () => setIsChatOpen(!isChatOpen);

  return (
    <GoogleOAuthProvider clientId="260464850273-cqq92h183m8qfg60ooi0mqcbe5ibni0d.apps.googleusercontent.com">
      <div className="relative min-h-screen bg-white">
        {/* Hiệu ứng tuyết rơi */}
        <Snowfall
          snowflakeCount={80}
          color="white"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10, // Đảm bảo tuyết nằm trên tất cả các thành phần khác
          }}
        />

        {/* Đèn nhấp nháy trên Header */}
 

        {/* Header */}
        <Header />

        {/* Banner Giáng Sinh */}
        <div className="bg-red-600 text-white text-center py-4 shadow-lg">
          <h1 className="text-3xl font-bold animate-blink">
            🎄 Chúc bạn một mùa Giáng Sinh an lành và hạnh phúc từ{" "}
            <span className="animate-blink">BusFree</span> 🎅
          </h1>

          {/* Định nghĩa animation trực tiếp trong thẻ style */}
          <style>
            {`
          @keyframes blink {
            0%, 100% { color: #ff0000; } /* Đỏ */
            25% { color: #00ff00; }     /* Xanh lá */
            50% { color: #ffff00; }     /* Vàng */
            75% { color: #00ffff; }     /* Xanh dương */
          }
          .animate-blink {
            animation: blink 1.5s infinite; /* Áp dụng keyframes */
          }
        `}
          </style>
        </div>
        {/* Nội dung trang */}
        <Banner />
        <NewsHome />
        <BusList />
        <BannerHome />
        <ColorfulBanner />
        <SupportForm />
        <Partners />

        {/* ChatBot */}
        <ChatBot isChatOpen={isChatOpen} toggleChat={toggleChat} />

        {/* Nút mở ChatBot */}
        {!isChatOpen && (
          <button
            onClick={toggleChat}
            className="fixed bottom-8 right-8 w-16 h-16  text-white rounded-full shadow-lg flex items-center justify-center hover:bg-red-700 transition-all duration-300 z-50"
          ></button>
        )}

        {/* Footer */}
        <Footer />

        {/* ScrollToTop */}
      </div>
    </GoogleOAuthProvider>
  );
};

export default TrangChu;
