import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../Components/SideBar';
import Header from '../Components/Header';

const CommentManagement = () => {
  const [searchInput, setSearchInput] = useState('');
  const [comments, setComments] = useState([]);
  const [dateFilter, setDateFilter] = useState('all');
  const { id } = useParams();

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.get(`http://localhost:8000/api/allratingforadmin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setComments(response.data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const toggleCommentVisibility = async (id, currentVisibility) => {
    try {
      const token = localStorage.getItem('access_token');
      const newVisibility = currentVisibility === 0 ? 1 : 0; // Đảo ngược trạng thái Hiện/Ẩn
  
      const response = await axios.put(
        `http://localhost:8000/api/ratingsGet/${id}`,
        { an_hien: newVisibility }, // Gửi trạng thái mới
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Cập nhật danh sách bình luận sau khi nhận phản hồi
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === id ? { ...comment, an_hien: response.data.data.an_hien } : comment
        )
      );
      console.log(response.data);
      console.log('Visibility updated successfully:', response.data.message);
    } catch (error) {
      console.error('Error updating comment visibility:', error);
      alert('Không thể cập nhật trạng thái bình luận. Vui lòng thử lại.');
    }
  };
  
  
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleString('vi-VN', {
      dateStyle: 'short',
      timeStyle: 'short',
      hour12: false,
    });
  };

  const getFilteredComments = () => {
    return comments.filter((comment) => {
      const lowerCaseSearch = searchInput.toLowerCase();
      return (
        comment.user.name.toLowerCase().includes(lowerCaseSearch) ||
        comment.user.email.toLowerCase().includes(lowerCaseSearch) ||
        comment.id.toString().includes(lowerCaseSearch) // Cho phép tìm kiếm theo số
      );
    });
  };

  const getFilteredAndSortedComments = () => {
    const filteredComments = getFilteredComments();

    // Sắp xếp theo ngày bình luận
    const sortedComments = filteredComments.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      if (dateFilter === 'newest') {
        return dateB - dateA; // Mới nhất
      } else if (dateFilter === 'oldest') {
        return dateA - dateB; // Cũ nhất
      }

      return 0; // Mặc định không sắp xếp
    });

    return sortedComments;
  };

  // Hàm hiển thị sao dựa trên rating
  const renderStars = (rating) => {
    const filledStars = Math.floor(rating); // Số sao đầy
    const emptyStars = 5 - filledStars; // Số sao rỗng

    return (
      <>
        {[...Array(filledStars)].map((_, index) => (
          <i key={`filled-${index}`} className="fas fa-star text-yellow-500"></i>
        ))}
        {[...Array(emptyStars)].map((_, index) => (
          <i key={`empty-${index}`} className="fas fa-star text-gray-300"></i>
        ))}
      </>
    );
  }
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header />
        <div className="flex-1 p-10 bg-white shadow-lg rounded-lg overflow-y-auto">
          {/* Tìm kiếm và Dropdown nằm cùng hàng */}
          <div className="flex items-center space-x-4 mb-6">
            {/* Tìm kiếm với icon */}
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Tìm kiếm theo tên sinh viên, email hoặc mã bình luận..."
                className="p-3 pl-10 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-700"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <i className="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
            </div>

            {/* Dropdown lọc ngày */}
            <select
              value={dateFilter}
              onChange={handleDateFilterChange}
              className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-700"
            >
              <option value="all">Tất cả</option>
              <option value="newest">Mới nhất</option>
              <option value="oldest">Cũ nhất</option>
            </select>
          </div>

          {/* Bảng bình luận */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead className="bg-gray-700 text-white">
                <tr>
                  {['Mã Bình Luận', 'Tên Sinh Viên', 'Chuyến đi', 'Bình Luận', 'Ngày bình luận', 'Số sao', 'Hành Động'].map(
                    (header) => (
                      <th key={header} className="py-3 px-5 text-left">
                        {header}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {getFilteredAndSortedComments().map((comment) => (
                  <tr key={comment.id} className="hover:bg-gray-100 transition-colors">
                    <td className="py-3 px-6 border-b">{comment.id}</td>
                    <td className="py-3 px-6 border-b">{comment.user.name}</td>
                    <td className="py-3 px-6 border-b">{comment.chuyendi.tenchuyen}</td>
                    <td className="py-3 px-6 border-b">{comment.review}</td>
                    <td className="py-3 px-6 border-b">{formatDateTime(comment.created_at)}</td>
                    <td className="py-3 px-6 border-b">{renderStars(comment.rating)}</td> {/* Hiển thị sao */}
                    <td className="py-3 px-6 border-b flex space-x-2">
                    <button
                      onClick={() => toggleCommentVisibility(comment.id, comment.an_hien)}
                      className={`text-white py-2 px-3 rounded ${
                        comment.an_hien ? 'bg-green-500 hover:bg-green-600': 'bg-red-500 hover:bg-red-600'
                      }`}
                    >
                      <i className={`fas ${comment.an_hien ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                    </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentManagement;
