import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const SeatSelection = () => {
  const [busDetail, setBusDetail] = useState(null);
  const [seats, setSeats] = useState([]);
  const [notification, setNotification] = useState("");
  const [selectedSeats, setSelectedSeats] = useState([]);
  const { id } = useParams(); // ID chuyến đi
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusDetail = async () => {
      try {
        // Lấy thông tin chi tiết xe buýt
        const busResponse = await fetch(
          `http://localhost:8000/api/trips/${id}`
        );
        const busData = await busResponse.json();
        setBusDetail(busData);

        // Lấy danh sách vé đã đặt
        const bookedResponse = await fetch(
          `http://localhost:8000/api/ve/${id}`
        );
        const bookedData = await bookedResponse.json();

        // Lọc danh sách ghế đã đặt
        const bookedSeats = bookedData.data.map((ticket) => ({
          seatNumber: ticket.chongoidat,
          idVe: ticket.id,
          isAvailable: ticket.id_user === null, // Ghế trống nếu id_user === null
        }));
        console.log(bookedSeats);

        const seatArray = bookedSeats.map((seat) => {
          const isWindowSeat =
            (seat.seatNumber - 1) % 10 === 0 || seat.seatNumber % 10 === 0;
          const isDoorSeat = seat.seatNumber === 1 || seat.seatNumber === 10;

          return {
            number: seat.seatNumber,
            isAvailable: seat.isAvailable,
            idVe: seat.idVe, // ID vé nếu đã đặt
            isWindowSeat,
            isDoorSeat,
          };
        });

        setSeats(seatArray);
      } catch (error) {
        console.error("Error fetching bus details:", error);
      }
    };

    fetchBusDetail();
  }, [id]);

  const handleSeatSelection = (seatNumber) => {
    const selectedSeat = seats.find((seat) => seat.number === seatNumber);

    if (!selectedSeat.isAvailable) {
      setNotification(`Ghế số ${seatNumber} đã được người khác chọn.`);
    } else {
      const isAlreadySelected = selectedSeats.some(
        (seat) => seat.seatNumber === seatNumber
      );

      if (isAlreadySelected) {
        // Bỏ chọn ghế
        setSelectedSeats((prev) =>
          prev.filter((seat) => seat.seatNumber !== seatNumber)
        );
        setNotification(`Bạn đã hủy chọn ghế số ${seatNumber}.`);
      } else {
        // Chọn ghế
        setSelectedSeats((prev) => [
          ...prev,
          { seatNumber, idVe: selectedSeat.idVe },
        ]);
        setNotification(`Bạn đã chọn ghế số ${seatNumber}.`);
      }
    }
  };

  const handleBooking = () => {
    if (selectedSeats.length === 0) {
      setNotification("Vui lòng chọn ít nhất một ghế trước khi đặt vé.");
      return;
    }

    const ticketIds = selectedSeats.map((seat) => seat.idVe); // Lấy danh sách ID vé
    const selectedSeatNumbers = selectedSeats.map((seat) => seat.seatNumber); // Hiển thị số ghế
    const bookingId = `VE-${selectedSeatNumbers.join("-")}`; // Mã đặt vé
    const purchaseDate = new Date().toLocaleString("vi-VN", {
      timeZone: "Asia/Ho_Chi_Minh",
    }); // Ngày giờ hiện tại
    const seatPrice = busDetail?.giachuyen || 0; // Giá mỗi ghế
    const totalPrice = selectedSeats.length * seatPrice; // Tổng tiền

    // Tạo thông tin đặt vé
    const bookingInfo = {
      id: ticketIds,
      bus: busDetail,
      selectedSeats: selectedSeatNumbers, // Danh sách số ghế
      ticketIds, // Danh sách mã vé
      purchaseDate, // Ngày đặt vé
      totalPrice, // Tổng tiền
    };

    // Lưu thông tin đặt vé vào localStorage
    localStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));

    // Thông báo thành công
    setNotification("Đặt vé thành công! Mã đặt vé của bạn là: " + bookingId);

    // Điều hướng đến trang thanh toán
    navigate("/pay");
  };

  return (
    <div className="flex justify-center items-center p-4">
    <div className="w-full max-w-4xl">
      <h3 className="text-xl font-semibold mb-4 text-center">
        Chọn ghế ngồi tại đây:
      </h3>
  
      {notification && (
        <div className="mb-3 text-center text-white bg-red-500 p-2 rounded border border-red-600 text-sm">
          {notification}
        </div>
      )}
  
      <div className="grid grid-cols-5 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10 gap-2 sm:gap-4">
        {seats.length > 0 ? (
          seats.map((seat, index) => (
            <div
              key={index}
              className={`seat cursor-pointer w-12 h-12 sm:w-16 sm:h-16 flex items-center justify-center text-xs sm:text-sm font-medium text-white rounded-sm border-2 border-solid ${
                selectedSeats.some(
                  (selectedSeat) => selectedSeat.seatNumber === seat.number
                )
                  ? "bg-purple-500 hover:bg-purple-600" // Màu khi ghế được chọn
                  : seat.isAvailable
                  ? seat.isWindowSeat
                    ? "bg-blue-500 hover:bg-blue-600" // Màu ghế cửa sổ
                    : seat.isDoorSeat
                    ? "bg-yellow-500 hover:bg-yellow-600" // Màu ghế gần cửa
                    : "bg-green-500 hover:bg-green-600" // Màu ghế còn lại
                  : "bg-gray-400 cursor-not-allowed" // Màu ghế không có sẵn
              }`}
              onClick={() => handleSeatSelection(seat.number)}
            >
              <span>Ghế {seat.number}</span>
            </div>
          ))
        ) : (
          <div className="col-span-5 sm:col-span-6 md:col-span-8 lg:col-span-10 text-center text-gray-600">
            Không có ghế sẵn có.
          </div>
        )}
      </div>
  
      <div className="mt-6 text-center">
        <button
          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
          onClick={handleBooking}
        >
          Đặt vé
        </button>
      </div>
    </div>
  </div>
  
  );
};

export default SeatSelection;
