import React from 'react';

const Partners = () => {
  return (
    <section className="partners text-center py-10 px-5 bg-white shadow-md">
      <h2 className="text-3xl text-gray-800 font-bold mb-10">ĐỐI TÁC CỦA BUSFREE</h2>
      <div className="partner-logos grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4">
        <div className="partner flex flex-col items-center border-2 border-green-600 rounded-lg p-4 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/HoangLong.webp" alt="Hoang Long" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Hoang Long</p>
        </div>
        <div className="partner flex flex-col items-center border-2 border-green-600 rounded-lg p-4 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/HanhCafe.webp" alt="Hanh Cafe" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Hanh Cafe</p>
        </div>
        <div className="partner flex flex-col items-center border-2 border-green-600 rounded-lg p-4 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/LongVanLImousine.webp" alt="Long Van Limousine" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Long Van Limousine</p>
        </div>
        <div className="partner flex flex-col items-center border-2 border-green-600 rounded-lg p-4 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/LuxuryTrans.webp" alt="LuxuryTrans" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">LuxuryTrans</p>
        </div>
        <div className="partner flex flex-col items-center border-2 border-green-600 rounded-lg p-4 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/TuanNga.webp" alt="Ben Tre Limousine" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Ben Tre Limousine</p>
        </div>
        <div className="partner flex flex-col items-center border-2 border-green-600 rounded-lg p-4 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/Futa.webp" alt="Futa" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Futa</p>
        </div>
      </div>
    </section>
  );
};

export default Partners;


