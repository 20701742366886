

import React, { useState } from 'react';

const SupportForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('http://localhost:8000/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccessMessage('Câu hỏi của bạn đã được gửi thành công.');
          setFormData({ name: '', email: '', phone: '', message: '' });
        } else {
          setErrorMessage('Đã gửi thành công');
        }
      })
      .catch((error) => {
        setErrorMessage('Chưa gửi thành công');
      });
  };

  return (
    <section className="support-form-section bg-white p-6 md:p-10 my-5 max-w-full mx-auto rounded-lg shadow-lg">
    <div className="form-container flex flex-col md:flex-row gap-5">
      {/* Left Column - Image */}
      <div className="form-image w-full md:w-1/2">
        <img
          src="/images/online-assistant-the-guy-sits-at-the-computer-communicates-with-clients-through-headphones-and-responds-to-messages-working-from-home-training-and-solving-everyday-problems-with-client.jpg"
          alt="Support Image"
          className="w-full h-auto rounded-lg object-cover"
        />
      </div>
  
      {/* Right Column - Form */}
      <div className="form-content flex flex-col justify-center w-full md:w-1/2">
        <h2 className="text-2xl text-[#2c3e50] mb-2">Liên hệ với chúng tôi</h2>
        <p className="text-lg mb-5 text-[#7f8c8d]">Đặt câu hỏi hoặc yêu cầu hỗ trợ về việc đặt vé.</p>
  
        {successMessage && <p className="text-red-500">{successMessage}</p>}
        {errorMessage && <p className="text-green-500">{errorMessage}</p>}
  
        <form className="support-form flex flex-col gap-4" onSubmit={handleSubmit}>
          <label htmlFor="name" className="text-left font-medium text-[#2c3e50]">Họ và tên</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Nhập họ và tên của bạn"
            required
            className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none"
          />
  
          <label htmlFor="email" className="text-left font-medium text-[#2c3e50]">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Nhập email của bạn"
            required
            className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none"
          />
  
          <label htmlFor="phone" className="text-left font-medium text-[#2c3e50]">Số điện thoại</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Nhập số điện thoại của bạn"
            required
            className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none"
          />
  
          <label htmlFor="message" className="text-left font-medium text-[#2c3e50]">Câu hỏi</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Nhập câu hỏi của bạn"
            rows="5"
            required
            className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none"
          />
  
          <button
            className="group/button relative inline-flex items-center justify-center overflow-hidden rounded-md bg-green-500 backdrop-blur-lg px-6 py-2 text-base font-semibold text-white transition-all duration-300 ease-in-out hover:scale-110 hover:shadow-xl hover:shadow-gray-600/50 border border-white/20" 
            type="submit"
          >
            <span className="text-lg">Gửi câu hỏi</span>
            <div
              className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-13deg)_translateX(-100%)] group-hover/button:duration-1000 group-hover/button:[transform:skew(-13deg)_translateX(100%)]"
            >
              <div className="relative h-full w-10 bg-white/20"></div>
            </div>
          </button>
        </form>
  
        <div className="contact-info mt-8 text-lg">
          <h3 className="mb-2 text-[#2c3e50]">Thông tin liên hệ:</h3>
          <p className="text-[#7f8c8d]">
            Email: <a href="mailto:support@websitedatve.com" className="text-green-500 hover:underline">support@websitedatve.com</a>
          </p>
          <p className="text-[#7f8c8d]">
            Hotline: <a href="tel:+84123456789" className="text-green-500 hover:underline">+84 123 456 789</a>
          </p>
        </div>
      </div>
    </div>
  </section>
  
  
  
  
  );
};

export default SupportForm;
