import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Slider from 'react-slick';
function NewsDetail() {
  const { id } = useParams(); // Lấy id từ URL
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]); // Lưu trữ bài viết liên quan
  const articles = [
    {
      id: 1,
      thumbnail: "https://via.placeholder.com/400x200",
      title: "Bài viết liên quan 1",
      content: "Nội dung bài viết liên quan 1. Đây là một đoạn văn ngắn.",
    },
    {
      id: 2,
      thumbnail: "https://via.placeholder.com/400x200",
      title: "Bài viết liên quan 2",
      content: "Nội dung bài viết liên quan 2. Đây là một đoạn văn ngắn.",
    },
    {
      id: 3,
      thumbnail: "https://via.placeholder.com/400x200",
      title: "Bài viết liên quan 3",
      content: "Nội dung bài viết liên quan 3. Đây là một đoạn văn ngắn.",
    },
    // Thêm các bài viết ảo khác nếu cần
  ];
  const settings = {
    dots: true, // Hiển thị chấm điều hướng
    infinite: true, // Cho phép cuộn vô hạn
    speed: 500, // Thời gian chuyển đổi giữa các bài viết
    slidesToShow: 1, // Hiển thị một slide tại một thời điểm
    slidesToScroll: 1, // Chuyển một slide tại mỗi lần cuộn
    autoplay: true, // Tự động chuyển slide
    autoplaySpeed: 3000, // Thời gian giữa các slide (3 giây)
  };
  useEffect(() => {
    // Lấy access_token từ localStorage hoặc sessionStorage
    const token = localStorage.getItem('access_token'); // Hoặc sessionStorage.getItem('access_token')

    // Gọi API để lấy bài viết theo id
    const fetchArticle = async () => {
      try {
        const response = await fetch(`http://localhost:8000/api/news/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Thêm token vào header
          },
        });

        const data = await response.json();

        console.log(data); // Kiểm tra dữ liệu trả về từ API

        if (data.message === 'Lấy bài viết thành công.' && data.data) {
          setArticle(data.data);

          // Sau khi lấy bài viết chính, lấy bài viết liên quan
          fetchRelatedArticles(data.data.id_doanh_muc);
        } else {
          console.error('Bài viết không tồn tại hoặc có lỗi.');
        }
      } catch (error) {
        console.error('Lỗi khi lấy bài viết:', error);
      }
    };

    // Gọi API để lấy bài viết liên quan
    const fetchRelatedArticles = async (categoryId) => {
      try {
        const response = await fetch(`http://localhost:8000/api/news/related?category=${categoryId}`);
        const data = await response.json();

        if (data.message === 'Lấy bài viết thành công.') {
          setRelatedArticles(data.data); // Lưu bài viết liên quan vào state
        } else {
          console.error('Không thể lấy bài viết liên quan.');
        }
      } catch (error) {
        console.error('Lỗi khi lấy bài viết liên quan:', error);
      }
    };

    fetchArticle();
  }, [id]); // Chạy lại khi id thay đổi

  // Nếu chưa có dữ liệu bài viết, hiển thị loading
  if (!article) {
    return <p>Đang tải bài viết...</p>;
  }

  return (
    <div className="font-roboto bg-gray-100">
      <Header />
      <main className="container mx-auto">
        {/* Div chứa bài viết */}
<div className="bg-white p-10 rounded-lg shadow-lg">
  <h1 className="text-4xl font-semibold text-gray-900 mb-6">{article.title}</h1>
{/* Nội dung bài viết */}
<p className="text-gray-700 text-lg leading-relaxed mb-8">{article.content}</p>
  {/* Ảnh bài viết */}
 {/* Ảnh bài viết */}
 <img
  src={article.thumbnail}
  alt="Article thumbnail"
  className="w-3/4 h-auto rounded-xl mb-8 shadow-xl mx-auto" // Thêm mx-auto để căn giữa ảnh
  style={{ maxWidth: '600px' }} // Giới hạn chiều rộng ảnh tối đa
/>

  {/* Phần tác giả */}
  <div className="flex items-center space-x-4">
    {/* Ảnh tác giả */}
    {article.admin && article.admin.img && (
      <img
        alt="Author's profile picture"
        className="w-12 h-12 rounded-full"  // Kích thước ảnh nhỏ lại
        src={article.admin.img}
      />
    )}
    <div>
      <p className="text-gray-800 font-semibold">By {article.admin?.ten || 'Unknown'}</p>
      <p className="text-gray-500 text-sm">Published on {new Date(article.created_at).toLocaleDateString()}</p>
    </div>
  </div>
</div>

        
       {/* Bài viết liên quan */}
{/* <div className="mt-12">
  <h2 className="text-3xl font-semibold text-gray-900 mb-6">Bài viết liên quan</h2>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
    {[
      {
        id: 1,
        thumbnail: "https://via.placeholder.com/400x200",
        title: "Bài viết liên quan 1",
        content: "Nội dung bài viết liên quan 1. Đây là một đoạn văn ngắn.",
      },
      {
        id: 2,
        thumbnail: "https://via.placeholder.com/400x200",
        title: "Bài viết liên quan 2",
        content: "Nội dung bài viết liên quan 2. Đây là một đoạn văn ngắn.",
      },
      {
        id: 3,
        thumbnail: "https://via.placeholder.com/400x200",
        title: "Bài viết liên quan 3",
        content: "Nội dung bài viết liên quan 3. Đây là một đoạn văn ngắn.",
      },
      // Thêm các bài viết ảo khác nếu cần
    ].map((related) => (
      <div key={related.id} className="bg-white p-6 rounded-lg shadow-lg">
        <img
          src={related.thumbnail}
          alt={related.title}
          className="w-full h-48 object-cover rounded-lg mb-4"
        />
        <h3 className="text-xl font-semibold text-gray-900">{related.title}</h3>
        <p className="text-gray-700 text-sm">{related.content.slice(0, 100)}...</p>
        <a
          href={`/news/${related.id}`}
          className="text-blue-500 mt-4 inline-block text-sm"
        >
          Đọc thêm
        </a>
      </div>
    ))}
  </div>
        </div> */}
      </main>
      <Footer />
    </div>
  );
}

export default NewsDetail;
