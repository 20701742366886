import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/20/solid";
import { PencilIcon, ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import Sidebar from "../Components/SideBar";
import Header from "../Components/Header";
function TripsManagement() {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [editTrip, setEditTrip] = useState(null);
  const [createTrip, setCreateTrip] = useState(false); // New state to control the "Add New Trip" form visibility
  const [deletedTrips, setDeletedTrips] = useState([]);
  const [formData, setFormData] = useState({
    idbuss: 1,
    idtuyenduong: 1,
    tenchuyen: "",
    thoigianbatdau: "",
    thoigianketthuc: "",
    giachuyen: "",
    mota: "",
    anhien: 1,
    trangthai: 29,
  });
  useEffect(() => {
    const fetchDeletedTrips = async () => {
      try {
        const token = localStorage.getItem("access_token"); // Lấy token từ localStorage hoặc nơi lưu trữ khác
        const response = await axios.get(
          "http://localhost:8000/api/tripsDelete",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Gửi token trong header
            },
          }
        );
        console.log("Dữ liệu chuyến đi đã xóa: ", response.data);
        setDeletedTrips(response.data);
      } catch (error) {
        console.error("Lỗi khi lấy danh sách chuyến đi đã xóa:", error);
        if (error.response && error.response.status === 401) {
          alert("Phiên làm việc hết hạn, vui lòng đăng nhập lại.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeletedTrips();
  }, []);
  const handleRestore = async (id) => {
    try {
      const token = localStorage.getItem("access_token"); // Lấy token từ localStorage (hoặc từ nơi lưu trữ khác)

      // Thêm token vào header của yêu cầu PATCH
      await axios.patch(
        `http://localhost:8000/api/trips/${id}/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Thêm Authorization header với Bearer token
          },
        }
      );

      alert("Tuyến đường đã được khôi phục!");
      setDeletedTrips(deletedTrips.filter((trip) => trip.id !== id));
    } catch (error) {
      console.error("Lỗi khi khôi phục tuyến đường:", error);
      alert("Không thể khôi phục tuyến đường. Vui lòng thử lại!");
    }
  };

  useEffect(() => {
    // Fetch the trips data from your Laravel API
    axios
      .get("http://localhost:8000/api/trips")
      .then((response) => {
        setTrips(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the trips!", error);
        setLoading(false);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Định dạng lại thời gian trước khi gửi
    const formattedStartTime = new Date(formData.thoigianbatdau)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const formattedEndTime = new Date(formData.thoigianketthuc)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    // Chuẩn bị dữ liệu gửi
    const updatedData = {
      id_buss: formData.idbuss,
      tenchuyen: formData.tenchuyen,
      id_tuyenduong: formData.idtuyenduong,
      thoigianbatdau: formattedStartTime,
      thoigianketthuc: formattedEndTime,
      giachuyen: Number(formData.giachuyen), // Đảm bảo giá trị là số
      mota: formData.mota,
      anhien: formData.anhien,
      trangthai: formData.trangthai,
    };

    console.log("Data being sent:", updatedData);

    const token = localStorage.getItem("access_token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Gửi yêu cầu POST
    axios
      .post("http://localhost:8000/api/trips", updatedData, config)
      .then((response) => {
        console.log("Response:", response.data);
        setTrips([...trips, response.data]); // Thêm chuyến mới vào danh sách
        setCreateTrip(false); // Đóng modal
        setFormData({}); // Reset form
        toast.success("Tạo chuyến đi thành công!");
      })
      .catch((error) => {
        console.error("Lỗi khi tạo chuyến đi:", error.response?.data || error);
        toast.error("Không thể tạo chuyến đi!");
      });
  };

  const handleEdit = (trip) => {
    setFormData({
      idchuyen: trip.idchuyen || null, // Đảm bảo có ID của chuyến đi
      idbuss: trip.idbuss || 0,
      idtuyenduong: trip.idtuyenduong || 1,
      thoigianbatdau: trip.thoigianbatdau || "",
      thoigianketthuc: trip.thoigianketthuc || "",
      giachuyen: trip.giachuyen || 0,
      mota: trip.mota || "",
      anhien: trip.anhien || 1,
      trangthai: trip.trangthai || 1,
    });

    setEditTrip(trip); // Đặt trạng thái chuyến đi đang chỉnh sửa
  };

  const handleDelete = (id) => {
    // Get the token from localStorage or another source where it's stored
    const token = localStorage.getItem("access_token"); // or use context or state if needed

    // Set up headers with the Authorization token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .delete(`http://localhost:8000/api/trips/${id}`, { headers })
      .then(() => {
        setTrips(trips.filter((trip) => trip.idchuyen !== id));
      })
      .catch((error) => {
        console.error("Error deleting the trip:", error);
      });
  };

  return (
    <>
      <div className="flex h-screen">
        <Sidebar />
        <div className="bg-gray-100 flex-1 flex flex-col">
          <Header />
          <div className="flex-1 overflow-y-auto p-4 ">
            <div className="p-4 shadow-md flex justify-between items-center">
              <button
                onClick={() => setCreateTrip(true)} // Show the create trip form
                className="bg-green-600 text-white px-4 py-2 rounded flex items-center space-x-2"
              >
                <i className="fas fa-plus"></i> {/* Icon add (plus) */}
                <span> Tạo mới chuyến</span>
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border">Mã</th>
                    <th className="py-2 px-4 border">Tên Xe</th>
                    <th className="py-2 px-4 border">Nơi xuất phát</th>
                    <th className="py-2 px-4 border">Nơi kết thúc</th>
                    <th className="py-2 px-4 border">Giá chuyến</th>
                    <th className="py-2 px-4 border">Ghế ngồi</th>
                    <th className="py-2 px-4 border">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {trips.map((trip) => (
                    <tr key={trip.idchuyen} className="border">
                      <td className="py-2 px-4 border">{trip.idchuyen}</td>
                      <td className="py-2 px-4 border">
                        {trip.chitietxebuss?.tenxe}
                      </td>
                      <td className="py-2 px-4 border">
                        {trip.chitiettuyenduong?.noixuatphat}
                      </td>
                      <td className="py-2 px-4 border">
                        {trip.chitiettuyenduong?.noiden}
                      </td>
                      <td className="py-2 px-4 border">{trip.giachuyen} VND</td>
                      <td className="py-2 px-4 border">
                        {trip.chitietxebuss?.sochongoi}
                      </td>
                      <td className="py-3 px-4 border flex justify-center items-center">
                        <button
                          className="bg-yellow-500 text-white p-2 rounded mr-2"
                          onClick={() => handleEdit(trip)}
                        >
                          <PencilIcon className="h-5 w-5" />
                        </button>
                        <button
                          className="bg-red-500 text-white p-2 rounded mr-2"
                          onClick={() => handleDelete(trip.idchuyen)}
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Add New Trip Modal */}
            {createTrip && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded shadow-lg w-3/4">
                  <h2 className="text-xl font-semibold mb-6">Tạo mới</h2>

                  <form onSubmit={handleSubmit}>
                    <div className="flex flex-wrap -mx-4">
                      {/* Buss ID */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="id_buss"
                        >
                          Mã xe
                        </label>
                        <input
                          type="number"
                          name="id_buss"
                          value={formData.id_buss}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* Route ID */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="id_tuyenduong"
                        >
                          Mã tuyến đường
                        </label>
                        <input
                          type="number"
                          name="id_tuyenduong"
                          value={formData.id_tuyenduong}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* Trip Name */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="tenchuyen"
                        >
                          Tên chuyến
                        </label>
                        <input
                          type="text"
                          name="tenchuyen"
                          value={formData.tenchuyen}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* Start Time */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="thoigianbatdau"
                        >
                          Thời gian bắt đầu
                        </label>
                        <input
                          type="datetime-local"
                          name="thoigianbatdau"
                          value={formData.thoigianbatdau}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* End Time */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="thoigianketthuc"
                        >
                          Thời gian kết thúc
                        </label>
                        <input
                          type="datetime-local"
                          name="thoigianketthuc"
                          value={formData.thoigianketthuc}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* Price */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="giachuyen"
                        >
                          Giá chuyến
                        </label>
                        <input
                          type="number"
                          name="giachuyen"
                          value={formData.giachuyen}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* Visibility */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="anhien"
                        >
                          Trạng thái hiển thị
                        </label>
                        <select
                          name="anhien"
                          value={formData.anhien}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                        >
                          <option value={1}>Hiển thị</option>
                          <option value={0}>Ẩn</option>
                        </select>
                      </div>

                      {/* Trip Status */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="trangthai"
                        >
                          Trạng thái chuyến đi
                        </label>
                        <select
                          name="trangthai"
                          value={formData.trangthai}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                        >
                          <option value={1}>Hoạt động</option>
                          <option value={0}>Ngừng hoạt động</option>
                        </select>
                      </div>

                      {/* Description */}
                      <div className="w-full px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="mota"
                        >
                          Mô tả
                        </label>
                        <textarea
                          name="mota"
                          value={formData.mota}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          rows={3}
                          placeholder="Nhập mô tả..."
                        />
                      </div>
                    </div>

                    <div className="flex justify-end mt-6">
                      <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                      >
                        Tạo mới
                      </button>
                      <button
                        type="button"
                        onClick={() => setCreateTrip(false)}
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                      >
                        Đóng
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* Edit Trip Modal */}
            {editTrip && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded shadow-lg w-3/4">
                  <h2 className="text-xl font-semibold mb-6">Chỉnh sửa</h2>

                  <form onSubmit={handleSubmit}>
                    <div className="flex flex-wrap -mx-4">
                      {/* Buss ID */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="id_buss"
                        >
                          Mã xe
                        </label>
                        <input
                          type="number"
                          name="idbuss"
                          value={formData.idbuss}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* Route ID */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="id_tuyenduong"
                        >
                          Mã tuyến đường
                        </label>
                        <input
                          type="number"
                          name="idtuyenduong"
                          value={formData.idtuyenduong}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* Start Time */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="thoigianbatdau"
                        >
                          Thời gian bắt đầu
                        </label>
                        <input
                          type="datetime-local"
                          name="thoigianbatdau"
                          value={formData.thoigianbatdau}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* End Time */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="thoigianketthuc"
                        >
                          Thời gian kết thúc
                        </label>
                        <input
                          type="datetime-local"
                          name="thoigianketthuc"
                          value={formData.thoigianketthuc}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>

                      {/* Price */}
                      <div className="w-full md:w-1/2 px-4 mb-4">
                        <label
                          className="block text-gray-700 mb-2"
                          htmlFor="giachuyen"
                        >
                          Giá chuyến
                        </label>
                        <input
                          type="number"
                          name="giachuyen"
                          value={formData.giachuyen}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex justify-end mt-6">
                      <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                      >
                        Lưu toàn bộ
                      </button>
                      <button
                        type="button"
                        onClick={() => setEditTrip(null)} // Close the modal
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                      >
                        Đóng
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {/* Deleted Routes Section */}
            <div className="p-4 bg-gray-100">
              <h1 className="text-2xl font-semibold text-gray-700 mb-4">
                Danh sách chuyến đi đã xóa
              </h1>
              {isLoading ? (
                <div className="flex justify-center items-center h-32">
                  <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-blue-500"></div>
                </div>
              ) : deletedTrips.length === 0 ? (
                <p className="text-gray-500">Không có dữ liệu</p>
              ) : (
                <table className="min-w-full table-auto bg-white shadow-lg rounded-lg">
                  <thead className="bg-gray-800 text-white">
                    <tr>
                      <th className="px-4 py-2 text-left">Mã</th>
                      <th className="px-4 py-2 text-left">Tên xe</th>
                      <th className="px-4 py-2 text-left">Nơi xuất phát</th>
                      <th className="px-4 py-2 text-left">Nơi kết thúc</th>
                      <th className="px-4 py-2 text-left">Giá chuyến</th>
                      <th className="px-4 py-2 text-left">Ghế ngồi</th>
                      <th className="px-4 py-2">Hành động</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700">
                    {deletedTrips.map((trip) => (
                      <tr
                        key={trip.idchuyen}
                        className="border-b hover:bg-gray-50"
                      >
                        <td className="px-4 py-3">{trip.idchuyen}</td>
                        <td className="px-4 py-3">
                          {trip.chitietxebuss?.tenxe}
                        </td>
                        <td className="px-4 py-3">
                          {trip.chitiettuyenduong?.noixuatphat}
                        </td>
                        <td className="px-4 py-3">
                          {trip.chitiettuyenduong?.noiden}
                        </td>
                        <td className="px-4 py-3">{trip.giachuyen}</td>
                        <td className="px-4 py-3">
                          {trip.chitietxebuss?.sochongoi}
                        </td>
                        <td className="px-4 py-3 flex justify-center space-x-3">
                          <button
                            className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 flex items-center justify-center"
                            onClick={() => handleRestore(trip.idchuyen)}
                          >
                            <i className="fas fa-undo"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TripsManagement;
