import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'; // Import icon hóa đơn
import { QRCodeSVG } from 'qrcode.react';
import Sidebar from '../Components/SideBar';
import Header from '../Components/Header';
import * as XLSX from 'xlsx';  // Import thư viện XLSX
import { saveAs } from 'file-saver';  // Import thư viện file-saver
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faPen,faTrashAlt } from '@fortawesome/free-solid-svg-icons';  // Import icon cây viết
import { useSnackbar } from 'notistack';
const TicketManagement = () => {
  const [tickets, setTickets] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTicket, setSelectedTicket] = useState(null); // Để lưu thông tin vé được chọn
  const [isModalOpen, setIsModalOpen] = useState(false); // Để điều khiển modal
  const [isPopupOpen, setIsPopupOpen] = useState(false);  // Để mở/đóng modal
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [ticketToUpdate, setTicketToUpdate] = useState(null); // Store ticket data for update
const [isDeleteByTripModalOpen, setIsDeleteByTripModalOpen] = useState(false);
const [tripIdToDelete, setTripIdToDelete] = useState('');
const { enqueueSnackbar } = useSnackbar();
  const [newTicketData, setNewTicketData] = useState({
    id_user: '',
    id_chuyen: '',
    mota: '',
    chongoidat: '',
    ngaydatve: '',
    trangthaive: '',
    ghichu: ''
  });
  // Hàm mở modal
const openPopup = () => {
  setIsPopupOpen(true);
};

// Hàm đóng modal
const closePopup = () => {
  setIsPopupOpen(false);
  setNewTicketData({
    id_user: '',
    id_chuyen: '',
    mota: '',
    chongoidat: '',
    ngaydatve: '',
    trangthaive: '',
    ghichu: ''
  });
};

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      setError(null);

      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) {
        setError('User is not authenticated. Please log in.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`http://localhost:8000/api/ve?page=${currentPage}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setTickets(response.data.data || []);
        setTotalPages(response.data.last_page || 1);
      } catch (err) {
        setError('Failed to load tickets. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages && page !== currentPage) {
      setCurrentPage(page);
    }
  };

  // Hàm mở modal và thiết lập vé được chọn
  const handleViewTicket = (ticket) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };

  // Hàm đóng modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTicket(null);
  };

  // Hàm xuất vé ra file Excel
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(tickets.map(ticket => ({
      'Ticket ID': ticket.id,
      'User ID': ticket.id_user,
      'Chuyen ID': ticket.id_chuyen,
      'Description': ticket.mota,
      'Seats Reserved': ticket.chongoidat,
      'Booking Date': new Date(ticket.ngaydatve).toLocaleString(),
      'Status': ticket.trangthaive,
      'Notes': ticket.ghichu,
    })));
    XLSX.utils.book_append_sheet(wb, ws, 'Tickets');
    
    // Tạo file Excel và tải về
    const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([excelFile], { type: 'application/octet-stream' }), 'tickets.xlsx');
  };

  const createTicket = async (newTicketData) => {
    const accessToken = localStorage.getItem('access_token');
    
    if (!accessToken) {
      setError('User is not authenticated. Please log in.');
      return;
    }
  
    try {
      // Gửi yêu cầu POST đến API của Laravel
      const response = await axios.post('http://localhost:8000/api/ve', newTicketData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      // Cập nhật danh sách vé sau khi tạo mới thành công
      setTickets((prevTickets) => [...prevTickets, response.data.data]);
      
      enqueueSnackbar('Thêm vé thành công!', { variant: 'success' ,anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 2000, // Thời gian hiển thị (ms)
    });
    setTimeout(() => {
      window.location.reload(); // Reload lại trang để cập nhật thông tin vé mới
    }, 500);
      closePopup(); // Đóng modal sau khi tạo vé thành công
    } catch (err) {
      setError('Failed to create ticket. Please try again later.');
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewTicketData({
      ...newTicketData,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    createTicket(newTicketData);  // Gọi hàm tạo vé
  };
    
  const handleEditTicket = (ticket) => {
    setTicketToUpdate(ticket); // Store ticket data for editing
    setIsUpdateModalOpen(true); // Open update modal
  };
  
  const updateTicket = async (updatedTicketData) => {
    const accessToken = localStorage.getItem('access_token');
    
    if (!accessToken) {
      setError('User is not authenticated. Please log in.');
      return;
    }
  
    try {
      const response = await axios.put(`http://localhost:8000/api/ve/${updatedTicketData.id}`, updatedTicketData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      // Update the tickets list with the updated ticket
      setTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket.id === updatedTicketData.id ? response.data.data : ticket
        )
      );
      enqueueSnackbar('cập nhật thành công!', { variant: 'success' ,anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 2000, // Thời gian hiển thị (ms)
    });
      setIsUpdateModalOpen(false); // Close the modal after update
    } catch (err) {
      setError('Failed to update ticket. Please try again later.');
    }
  };
  const deleteTicket = async (ticketId) => {
    const accessToken = localStorage.getItem('access_token');
  
    if (!accessToken) {
      alert('User is not authenticated');
      return;
    }
  
    try {
      const response = await axios.delete(`http://localhost:8000/api/ve/delete/${ticketId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      enqueueSnackbar('Xóa vé thành công!', { variant: 'success' ,anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 2000, // Thời gian hiển thị (ms)
    });
      // Cập nhật lại danh sách vé
      setTickets(tickets.filter(ticket => ticket.id !== ticketId));
    } catch (error) {
      console.error('Error deleting ticket:', error);
      alert('Failed to delete ticket. Please try again later.');
    }
  };
  const deleteTicketByChuyen = async () => {
    const accessToken = localStorage.getItem('access_token');
    
    // Kiểm tra đăng nhập
    if (!accessToken) {
      alert('Người dùng chưa đăng nhập');
      return;
    }
  
    // Kiểm tra ID chuyến đã được nhập
    if (!tripIdToDelete.trim()) {
      alert('Vui lòng nhập ID chuyến');
      return;
    }
  
    try {
      // Gọi API xóa vé
      const response = await axios.delete(
        `http://localhost:8000/api/ve/delete-by-chuyen/${tripIdToDelete}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );
      
       enqueueSnackbar('Xóa vé thành công!', { variant: 'success' ,anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 2000, // Thời gian hiển thị (ms)
        });
      // Đóng modal và reset form
      setIsDeleteByTripModalOpen(false);
      setTripIdToDelete('');
      
      // Cập nhật lại danh sách vé
     // Reload trang sau khi xóa vé và cập nhật dữ liệu
     setTimeout(() => {
      window.location.reload(); // Reload lại trang để cập nhật thông tin vé mới
    },500);
    } catch (error) {
      console.error('Lỗi khi xóa vé:', error);
      alert('Xóa vé thất bại. Vui lòng thử lại sau.');
    }
  };
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header />
        <div className="mt-3 text-center pl-5 flex justify-end space-x-4">
          <button
          onClick={openPopup}
          className="bg-blue-600 text-white px-10 py-3 rounded-lg hover:bg-blue-700 transition duration-300 flex items-center space-x-2"
          >
          {/* SVG Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            className="w-6 h-6"
          >
            <path
              d="M12 5c.552 0 1 .448 1 1v5h5c.552 0 1 .448 1 1s-.448 1-1 1h-5v5c0 .552-.448 1-1 1s-1-.448-1-1v-5H6c-.552 0-1-.448-1-1s.448-1 1-1h5V6c0-.552.448-1 1-1z"
            />
          </svg>

          {/* Text */}
          <span>Tạo vé</span>
          </button>
          {/* Export to Excel Button */}
          <button
            onClick={exportToExcel}
            className="bg-green-600 text-white px-6 py-4 rounded-lg hover:bg-green-700 transition duration-300 flex items-center justify-center text-sm"
          >
            <FontAwesomeIcon icon={faFileExcel} className="mr-6 text-lg" />
            Excel
          </button>
          <button
          onClick={() => setIsDeleteByTripModalOpen(true)}
          className="bg-red-600 text-white px-3 py-2 rounded-lg hover:bg-red-700 flex items-center space-x-2"
          title="Xóa tất cả vé"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
          <span className="text-xs">Tất cả</span>
        </button>

        </div>

        {/* Error Message */}
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <br></br>
        {/* Loading Spinner */}
          <>
            {/* Ticket Table */}
            <div className="overflow-x-auto bg-white shadow-lg border border-gray-200 ">
              <table className="min-w-full table-auto">
                <thead className="bg-gray-700 text-white">
                  <tr>
                    <th className="px-3 py-2 text-left font-semibold text-sm">Mã vé</th>
                    <th className="px-3 py-2 text-left font-semibold text-sm">Mã User</th>
                    <th className="px-3 py-2 text-left font-semibold text-sm">Mã Chuyến</th>
                    <th className="px-3 py-2 text-left font-semibold text-sm">Mô tả</th>
                    <th className="px-3 py-2 text-left font-semibold text-sm">Ghế đã đặt</th>
                    <th className="px-3 py-2 text-left font-semibold text-sm">Ngày đặt</th>
                    <th className="px-3 py-2 text-left font-semibold text-sm">Trạng thái</th>
                    <th className="px-3 py-2 text-left font-semibold text-sm">Ghi chú</th>
                    <th className="px-3 py-2 text-left font-semibold text-sm">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                {tickets && tickets.length > 0 ? (
  tickets.map((ticket) => {
    if (!ticket || !ticket.id) return null; // Kiểm tra nếu ticket không hợp lệ, bỏ qua
    return (
      <tr key={ticket.id} className="border-b hover:bg-gray-100 transition-all">
        <td className="px-3 py-2 text-sm">{ticket.id}</td>
        <td className="px-3 py-2 text-sm">{ticket.id_user}</td>
        <td className="px-3 py-2 text-sm">{ticket.id_chuyen}</td>
        <td className="px-3 py-2 text-sm">{ticket.mota}</td>
        <td className="px-3 py-2 text-sm">{ticket.chongoidat}</td>
        <td className="px-3 py-2 text-sm">{new Date(ticket.ngaydatve).toLocaleString()}</td>
        <td className="px-3 py-2 text-sm">{ticket.trangthaive}</td>
        <td className="px-3 py-2 text-sm">{ticket.ghichu}</td>
        <td className="px-3 py-2 text-sm">
          {/* Nút xem chi tiết */}
          <button
            className="bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => handleViewTicket(ticket)}
            title="View Ticket"
          >
            <FontAwesomeIcon icon={faFileInvoice} className="text-xl" />
          </button>

          {/* Nút chỉnh sửa */}
          <button
            className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:scale-105 mx-2"
            onClick={() => handleEditTicket(ticket)}
            title="Edit Ticket"
          >
            <FontAwesomeIcon icon={faPen} className="text-xl" />
          </button>

          {/* Nút xóa */}
          <button
            onClick={() => deleteTicket(ticket.id)}
            className="bg-red-600 text-white p-2 rounded-lg hover:bg-red-700 transition duration-300 ease-in-out transform hover:scale-105"
            title="Delete"
          >
            <FontAwesomeIcon icon={faTrashAlt} className="text-xl" />
          </button>
        </td>
      </tr>
    );
  })
) : (
  <tr>
    <td className="px-3 py-2 text-center text-gray-500" colSpan="9">
      No tickets found.
    </td>
  </tr>
)}
</tbody>

              </table>
            </div>

            <div className="mt-4 flex justify-center">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 bg-gray-200 mx-1 rounded disabled:opacity-50"
        >
          Trước
        </button>
        <span className="px-3 py-1">{`Trang ${currentPage} / ${totalPages}`}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 bg-gray-200 mx-1 rounded disabled:opacity-50"
        >
          Sau
        </button>
      </div>
            {/* Modal to show ticket details */}
            {isModalOpen && selectedTicket && (
              <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
  <div className="bg-white p-6 rounded-lg max-w-3xl w-full shadow-lg">
    <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">Ticket Details</h2>

    {/* Chia thành 2 cột */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      {/* Cột 1: Thông tin vé */}
      <div className="space-y-4 text-gray-700">
        <p><strong className="text-gray-800">Mã vé:</strong> {selectedTicket.id}</p>
        <p><strong className="text-gray-800">Mã User:</strong> {selectedTicket.id_user}</p>
        <p><strong className="text-gray-800">Mã chuyến:</strong> {selectedTicket.id_chuyen}</p>
        <p><strong className="text-gray-800">Mô tả:</strong> {selectedTicket.mota}</p>
        <p><strong className="text-gray-800">Ghế đã đặt:</strong> {selectedTicket.chongoidat}</p>
        <p><strong className="text-gray-800">Ngày đặt:</strong> {new Date(selectedTicket.ngaydatve).toLocaleString()}</p>
        <p><strong className="text-gray-800">Trạng thái:</strong> {selectedTicket.trangthaive}</p>
        <p><strong className="text-gray-800">Ghi chú:</strong> {selectedTicket.ghichu}</p>
      </div>

      {/* Cột 2: Mã QR */}
      <div className="flex justify-center items-center">
        <QRCodeSVG value={`Ticket ID: ${selectedTicket.id}`} size={180} />
      </div>
    </div>

    {/* Nút đóng */}
    <div className="flex justify-end">
      <button
        onClick={() => setIsModalOpen(false)}
        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
      >
        Đóng
      </button>
    </div>
  </div>
</div>

            )}
            
          </>
          
        {isPopupOpen && (
  <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
      <h2 className="text-2xl font-semibold mb-4 text-center">Tạo vé mới</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">Mã chuyến</label>
            <input
              type="text"
              name="id_chuyen"
              value={newTicketData.id_chuyen}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
        </div>
        <div className="mt-4 text-center">
          <button
            type="submit"
            className="bg-blue-600 text-white px-10 py-3 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Tạo vé
          </button>
        </div>
      </form>
      <div className="flex justify-end mt-4">
        <button
          onClick={closePopup}
          className="bg-gray-600 text-white px-5 py-2 rounded-lg hover:bg-gray-700"
        >
         Đóng
        </button>
      </div>
    </div>
  </div>
)}
{isUpdateModalOpen && ticketToUpdate && (
  <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
      <h2 className="text-2xl font-semibold mb-4 text-center">Chỉnh sửa vé</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          updateTicket(ticketToUpdate); // Update the ticket
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">Mã User</label>
            <input
              type="text"
              name="id_user"
              value={ticketToUpdate.id_user}
              onChange={(e) => setTicketToUpdate({ ...ticketToUpdate, id_user: e.target.value })}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Mã Chuyến</label>
            <input
              type="text"
              name="id_chuyen"
              value={ticketToUpdate.id_chuyen}
              onChange={(e) => setTicketToUpdate({ ...ticketToUpdate, id_chuyen: e.target.value })}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Mô tả</label>
            <textarea
              name="mota"
              value={ticketToUpdate.mota}
              onChange={(e) => setTicketToUpdate({ ...ticketToUpdate, mota: e.target.value })}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Ghế đã đặt</label>
            <input
              type="text"
              name="chongoidat"
              value={ticketToUpdate.chongoidat}
              onChange={(e) => setTicketToUpdate({ ...ticketToUpdate, chongoidat: e.target.value })}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Ngày đặt</label>
            <input
              type="datetime-local"
              name="ngaydatve"
              value={ticketToUpdate.ngaydatve}
              onChange={(e) => setTicketToUpdate({ ...ticketToUpdate, ngaydatve: e.target.value })}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Trạng thái</label>
            <input
              type="text"
              name="trangthaive"
              value={ticketToUpdate.trangthaive}
              onChange={(e) => setTicketToUpdate({ ...ticketToUpdate, trangthaive: e.target.value })}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Ghi chú</label>
            <textarea
              name="ghichu"
              value={ticketToUpdate.ghichu}
              onChange={(e) => setTicketToUpdate({ ...ticketToUpdate, ghichu: e.target.value })}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
        </div>
        <div className="mt-4 text-center">
          <button
            type="submit"
            className="bg-blue-600 text-white px-10 py-3 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Chỉnh sửa vé
          </button>
        </div>
      </form>
      <div className="flex justify-end mt-4">
        <button
          onClick={() => setIsUpdateModalOpen(false)} // Close the modal without updating
          className="bg-gray-600 text-white px-5 py-2 rounded-lg hover:bg-gray-700"
        >
          Đóng
        </button>
      </div>
    </div>
  </div>
)}
{isDeleteByTripModalOpen && (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full">
              <h2 className="text-2xl font-semibold mb-4 text-center">Xóa tất cả vé theo ID Chuyến</h2>
              
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Vui lòng nhập Mã chuyến
                </label>
                <input
                  type="text"
                  value={tripIdToDelete}
                  onChange={(e) => setTripIdToDelete(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                  placeholder="Enter trip ID to delete all related tickets"
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => {
                    setIsDeleteByTripModalOpen(false);
                    setTripIdToDelete('');
                  }}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
                >
                  Đóng
                </button>
                <button
                  onClick={deleteTicketByChuyen}
                  className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-300"
                >
                  Xóa vé
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
      
    </div>
    
  );
};

export default TicketManagement;
