import React, { useState, useEffect } from 'react';

const NewsHome = () => {
  const [news, setNews] = useState([]); // Danh sách tin tức
  const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
  const [lastPage, setLastPage] = useState(null); // Tổng số trang
  const [scrollOffset, setScrollOffset] = useState(0); // Vị trí cuộn

  useEffect(() => {
    // Gọi API để lấy tin tức theo trang
    const fetchNews = async (page) => {
      try {
        const response = await fetch(`http://localhost:8000/api/news?page=${page}`);
        const data = await response.json();
        if (data && data.data) {
          setNews((prevNews) => [...prevNews, ...data.data]); // Ghép tin tức mới vào danh sách hiện tại
          setCurrentPage(data.current_page);
          setLastPage(data.last_page);
        }
      } catch (error) {
        console.error('Lỗi khi lấy tin tức:', error);
      }
    };

    fetchNews(currentPage);
  }, [currentPage]);

  const handleNext = () => {
    setScrollOffset((prev) => prev - 320); // Dịch chuyển sang trái
  };

  const handlePrev = () => {
    setScrollOffset((prev) => prev + 320); // Dịch chuyển sang phải
  };

  if (news.length === 0) {
    return <div className="text-center text-gray-600 mt-10">Đang tải tin tức...</div>;
  }

  return (
    <>
      <br />
      <h1 className="text-3xl font-bold text-center mb-6 text-black">TIN TỨC</h1>
      <div className="relative bg-gray-100 p-4">
        {/* Nút chuyển */}
        <button
          onClick={handlePrev}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-green-700 text-white p-2 rounded-full shadow-lg z-10 hover:bg-black"
        >
          &#9664;
        </button>
        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-green-700 text-white p-2 rounded-full shadow-lg z-10 hover:bg-black"
        >
          &#9654;
        </button>

        {/* Danh sách tin tức */}
        <div className="overflow-hidden">
          <div
            className="flex gap-6 transition-transform duration-300"
            style={{ transform: `translateX(${scrollOffset}px)` }}
          >
            {news.map((item) => (
              <div
                key={item.id}
                className="news-card bg-white p-4 rounded-lg shadow-lg border border-gray-200 min-w-[300px] sm:min-w-[250px] md:min-w-[300px] lg:min-w-[320px] hover:shadow-2xl transition-shadow duration-300"
              >
                {/* Ảnh thu nhỏ */}
                <img
                  src={item.thumbnail || 'https://via.placeholder.com/150'}
                  alt={item.title}
                  className="w-full h-40 rounded-lg object-cover mb-4"
                />

                {/* Nội dung tin tức */}
                <h3 className="text-lg font-semibold text-gray-800 hover:text-green-700 transition-colors duration-200">
                  {item.title}
                </h3>
                <p className="text-sm text-gray-600 mt-2">
                  Lượt xem: <span className="text-gray-800 font-medium">{item.views}</span>
                </p>
                <div className="flex items-center gap-3 text-sm text-gray-500 mt-4">
                  <img
                    src={item.admin?.img || 'https://via.placeholder.com/40'}
                    alt={item.admin?.ten || 'Admin'}
                    className="w-8 h-8 rounded-full object-cover border border-gray-300"
                  />
                  <span>
                    Người viết: <span className="font-medium text-gray-700">{item.admin?.ten || 'Không rõ'}</span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsHome;
