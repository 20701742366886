// import { useState, useEffect } from 'react';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import { Link, useNavigate } from "react-router-dom";
// import React from "react";

// const AccountDropdown = () => {
//     const navigate = useNavigate();
//     const [userName, setUserName] = useState(null); // State for user name
//     const [isOpen, setIsOpen] = useState(false);

//     useEffect(() => {
//         const name = localStorage.getItem("user_name");
//         setUserName(name); // Set user name from local storage
//     }, []); // Empty dependency array to run only once on mount

//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleLogout = async () => {
//         try {
//             const response = await fetch('http://localhost:8000/api/logout', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${localStorage.getItem("access_token")}`
//                 },
//             });

//             if (response.ok) {
//                 localStorage.removeItem("access_token");
//                 localStorage.removeItem("user_name");
//                 setUserName(null); // Clear user name from state
//                 navigate("/"); // Redirect to homepage after logout
//             } else {
//                 console.error("Logout failed.");
//             }
//         } catch (error) {
//             console.error("Error during logout:", error);
//         }
//     };

//     return (
//         <li className="relative">
//             <a
//                 href="#"
//                 onClick={toggleDropdown}
//                 className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300"
//             >
//                 <i className="fas fa-user text-lg mr-1 px-3 text-2xl"></i> Tài khoản
//             </a>
//             <div className={`absolute left-0 ${isOpen ? 'block' : 'hidden'} bg-white min-w-[200px] rounded-md shadow-lg z-10 mt-2`}>
//                 <Link to="/register" className="block text-black hover:bg-gray-200 p-3 rounded-md">Đăng ký</Link>
//                 {userName ? (
//                     <div>
//                         <span className="mr-4">Welcome, {userName}!</span>
//                         <button onClick={handleLogout} className="text-red-500">
//                             Logout
//                         </button>
//                     </div>
//                 ) : (
//                     <Link to="/login" className="block text-black hover:bg-gray-200 p-3 rounded-md">Đăng nhập</Link>
//                 )}
//                 <Link to="/profile" className="block text-black hover:bg-gray-200 p-3 rounded-md">Thông tin của tôi</Link>
//                 <a href="#" className="block text-black hover:bg-gray-200 p-3 rounded-md">Đơn hàng của tôi</a>
//             </div>
//         </li>
//     );
// };

// export default AccountDropdown;

// import { useState, useEffect } from 'react';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import { Link, useNavigate } from "react-router-dom";
// import React from "react";

// const AccountDropdown = () => {
//     const navigate = useNavigate();
//     const userEmail = localStorage.getItem("user_email");
//     const userName = localStorage.getItem('user_name');
//     const [isOpen, setIsOpen] = useState(false);

//     // useEffect(() => {
//     //     const name = localStorage.getItem("ten"); // Fetch the user's name from local storage
//     //     setUserName(name); // Set user name from local storage
//     // }, []); // Empty dependency array to run only once on mount
 
//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleLogout = () => {
//         localStorage.removeItem("access_token");
//         localStorage.removeItem("user_email");
//         localStorage.removeItem('user_name');
//         navigate("/login");
//       };
//     return (
//         <li className="relative">
//             <a
//                 href="#"
//                 onClick={toggleDropdown}
//                 className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300"
//             >
//                 <i className="fas fa-user text-lg mr-1 px-3 text-2xl"></i> Tài khoản
//             </a>
//             <div className={`absolute right-0 ${isOpen ? 'block' : 'hidden'} bg-white min-w-[200px] rounded-md shadow-lg z-10 mt-2`}>

                
//                 {userName  ? (
//                    <div className="p-5 bg-white rounded-lg shadow-lg w-64">
//                    <p className="text-xl font-bold text-gray-900 mb-3">
//                      Welcome, <span className="text-green-600 text-xs">{userName}</span>
//                    </p>
                   
//                    <button
//                      onClick={handleLogout}
//                      className="w-full py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors duration-300"
//                    >
//                      Logout
//                    </button>
                 
//                    <hr className="my-4 border-gray-200" />
                 
//                    <Link
//                      to="/profile"
//                      className="block py-2 text-gray-700 font-medium rounded-lg hover:bg-green-100 hover:text-green-800 transition-colors duration-200"
//                    >
//                      Thông tin của tôi
//                    </Link>
                 
//                    <Link
//                      to="#"
//                      className="block py-2 text-gray-700 font-medium rounded-lg hover:bg-green-100 hover:text-green-800 transition-colors duration-200"
//                    >
//                      Đơn hàng của tôi
//                    </Link>
//                  </div>
                 
                  
                    
//                 ) : (
//                     <Link to="/login" className="block text-black hover:bg-gray-200 p-3 rounded-md">Đăng nhập</Link>
//                 )}
               
//             </div>
//         </li>
//     );
// };

// export default AccountDropdown;
import { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link, useNavigate } from "react-router-dom";
import React from "react";

const AccountDropdown = () => {
    const navigate = useNavigate();
    const userEmail = localStorage.getItem("user_email");
    const userName = localStorage.getItem("user_name"); // Lấy tên người dùng từ localStorage
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_email");
        localStorage.removeItem("user_name"); // Xóa tên người dùng khỏi localStorage khi đăng xuất
        navigate("/");
    };

    return (
      <li className="relative z-20"> {/* Thêm z-20 để đảm bảo dropdown không bị che khuất */}
      <a
        href="#"
        onClick={toggleDropdown}
        className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300"
      >
        <i className="fas fa-user text-lg mr-1 px-3 text-2xl"></i>
        {userName || "Tài khoản"}
      </a>
    
      {/* Dropdown */}
      <div className={`absolute right-0 ${isOpen ? 'block' : 'hidden'} bg-white min-w-[200px] rounded-md shadow-lg z-30 mt-2`}>
        {userEmail ? (
          <div className="p-5 bg-white rounded-lg shadow-lg w-64">
            <p className="text-xl font-bold text-gray-900 mb-3">
              Chào bạn, <span className="text-green-600 text-xs">{userName}</span>
            </p>
            <button
              className="flex justify-center items-center gap-2 w-36 h-12 cursor-pointer rounded-md shadow-2xl text-white font-semibold bg-gradient-to-r from-[#fb7185] via-[#e11d48] to-[#be123c] hover:shadow-xl hover:shadow-red-500 hover:scale-105 duration-300 hover:from-[#be123c] hover:to-[#fb7185] mx-auto"
              onClick={handleLogout}
            >
              Đăng xuất
              <i className="fa fa-sign-out-alt w-5 fill-white"></i>
            </button>
      
            <hr className="my-4 border-gray-200" />
            <Link
              to="/profile"
              className="block py-2 text-gray-700 font-medium rounded-lg hover:bg-green-100 hover:text-green-800 transition-colors duration-200"
            >
              Thông tin của tôi
            </Link>
          </div>
        ) : (
          <div>
            <Link to="/login" className="block text-black hover:bg-gray-200 p-3 rounded-md">Đăng nhập</Link>
            <Link to="/register" className="block text-black hover:bg-gray-200 p-3 rounded-md">Đăng ký</Link>
          </div>
        )}
      </div>
    
      {/* Mobile Dropdown */}
      <div className={`absolute right-0 ${isOpen ? 'block' : 'hidden'} bg-white min-w-[200px] rounded-md shadow-lg z-30 mt-2 md:hidden`}>
        {userEmail ? (
          <div className="p-5 bg-white rounded-lg shadow-lg w-64">
            <p className="text-xl font-bold text-gray-900 mb-3">
              Chào bạn, <span className="text-green-600 text-xs">{userName}</span>
            </p>
            <button
              className="flex justify-center items-center gap-2 w-36 h-12 cursor-pointer rounded-md shadow-2xl text-white font-semibold bg-gradient-to-r from-[#fb7185] via-[#e11d48] to-[#be123c] hover:shadow-xl hover:shadow-red-500 hover:scale-105 duration-300 hover:from-[#be123c] hover:to-[#fb7185] mx-auto"
              onClick={handleLogout}
            >
              Đăng xuất
              <i className="fa fa-sign-out-alt w-5 fill-white"></i>
            </button>
      
            <hr className="my-4 border-gray-200" />
            <Link
              to="/profile"
              className="block py-2 text-gray-700 font-medium rounded-lg hover:bg-green-100 hover:text-green-800 transition-colors duration-200"
            >
              Thông tin của tôi
            </Link>
          </div>
        ) : (
          <div>
            <Link to="/login" className="block text-black hover:bg-gray-200 p-3 rounded-md">Đăng nhập</Link>
            <Link to="/register" className="block text-black hover:bg-gray-200 p-3 rounded-md">Đăng ký</Link>
          </div>
        )}
      </div>
    </li>
    

    
      
    );
};

export default AccountDropdown;

