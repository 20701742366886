import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../Components/SideBar';
import Header from '../Components/Header';

const RouteManagement = () => {
  const [routes, setRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deletedRoutes, setDeletedRoutes] = useState([]);
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    noixuatphat: '',
    noiden: '',
    vitri_batdau: { latitude: '', longitude: '' },
    vitri_ketthuc: { latitude: '', longitude: '' },
    quangduong: '',
    anhien: 1,
  });

  useEffect(() => {
    const fetchDeletedRoutes = async () => {
      try {
        const token = localStorage.getItem('access_token'); // Lấy token từ localStorage hoặc nơi lưu trữ khác
        const response = await axios.get('http://localhost:8000/api/tuyenduongDelete', {
          headers: {
            Authorization: `Bearer ${token}`,  // Gửi token trong header
          },
        });
        console.log("Dữ liệu tuyến đường đã xóa: ", response.data);
        setDeletedRoutes(response.data);
      } catch (error) {
        console.error('Lỗi khi lấy danh sách tuyến đường đã xóa:', error);
        if (error.response && error.response.status === 401) {
          alert("Phiên làm việc hết hạn, vui lòng đăng nhập lại.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeletedRoutes();
  }, []);
  console.log(selectedRoute);
  const handleRestore = async (id) => {
    try {
      const token = localStorage.getItem('access_token'); // Lấy token từ localStorage (hoặc từ nơi lưu trữ khác)
      
      // Thêm token vào header của yêu cầu PATCH
      await axios.patch(
        `http://localhost:8000/api/tuyenduong/${id}/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Thêm Authorization header với Bearer token
          },
        }
      );
  
      alert('Tuyến đường đã được khôi phục!');
      setDeletedRoutes(deletedRoutes.filter((route) => route.id !== id));
    } catch (error) {
      console.error('Lỗi khi khôi phục tuyến đường:', error);
      alert('Không thể khôi phục tuyến đường. Vui lòng thử lại!');
    }
  };

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await axios.get(`http://localhost:8000/api/tuyenduong?page=${currentPage}`);
        setRoutes(response.data.data);
        setTotalPages(response.data.last_page);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching routes:', error);
        setIsLoading(false);
      }
    };
    fetchRoutes();
  }, [currentPage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name.startsWith('vitri_batdau_') || name.startsWith('vitri_ketthuc_')) {
      const [key, field] = name.split('_');
      setFormData((prev) => ({
        ...prev,
        [key]: { ...prev[key], [field]: parseFloat(value) || 0 },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  
  

  const handleAddRoute = async () => {
    if (!validateForm()) return; // Kiểm tra dữ liệu trước khi gửi
  
    try {
      const token = localStorage.getItem('access_token');
      const data = prepareFormData();
      const response = await axios.post('http://localhost:8000/api/tuyenduong', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRoutes([...routes, response.data]);
      setShowForm(false);
      resetForm();
    } catch (error) {
      console.error('Lỗi khi thêm tuyến đường:', error);
      alert('Đã có lỗi xảy ra khi thêm tuyến đường. Vui lòng thử lại!');
    }
  };
  
  const handleEditRoute = async () => {
    if (!selectedRoute) return;
    if (!validateForm()) return; // Kiểm tra dữ liệu trước khi gửi
  
    try {
      const token = localStorage.getItem('access_token');
      const data = prepareFormData();
      const response = await axios.put(
        `http://localhost:8000/api/tuyenduong/${selectedRoute.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRoutes(routes.map((route) => (route.id === selectedRoute.id ? response.data : route)));
      setSelectedRoute(null);
      setShowForm(false);
      resetForm();
    } catch (error) {
      console.error('Lỗi khi chỉnh sửa tuyến đường:', error);
      alert('Đã có lỗi xảy ra khi chỉnh sửa tuyến đường. Vui lòng thử lại!');
    }
  };
  
  

  const resetForm = () => {
    setFormData({
      noixuatphat: selectedRoute?.noixuatphat || '',
      noiden: selectedRoute?.noiden || '',
      vitri_batdau: selectedRoute?.vitri_batdau || { latitude: '', longitude: '' },
      vitri_ketthuc: selectedRoute?.vitri_ketthuc || { latitude: '', longitude: '' },
      quangduong: selectedRoute?.quangduong || '',
      anhien: selectedRoute?.anhien || 1,
    });
  };
  
  const prepareFormData = () => {
    return {
      ...formData,
      vitri_batdau: {
        latitude: parseFloat(formData.vitri_batdau.latitude) || 0,
        longitude: parseFloat(formData.vitri_batdau.longitude) || 0,
      },
      vitri_ketthuc: {
        latitude: parseFloat(formData.vitri_ketthuc.latitude) || 0,
        longitude: parseFloat(formData.vitri_ketthuc.longitude) || 0,
      },
    };
  };
  const toggleForm = () => {
    setSelectedRoute(null); // Xóa dữ liệu của tuyến đường được chọn
    setShowForm((prev) => !prev); // Hiển thị/ẩn form
    resetForm(); // Đặt lại dữ liệu form
  };
  

  const handleDeleteRoute = async (id) => {
    try {
      const token = localStorage.getItem('access_token'); // Lấy token từ localStorage (hoặc từ nơi lưu trữ khác)
      
      // Thêm token vào header của yêu cầu DELETE
      await axios.delete(`http://localhost:8000/api/tuyenduong/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Thêm Authorization header với Bearer token
        },
      });
  
      // Cập nhật lại danh sách tuyến đường sau khi xóa
      setRoutes(routes.filter((route) => route.id !== id));
    } catch (error) {
      console.error('Lỗi khi xóa tuyến đường:', error);
    }
  };
  const validateForm = () => {
    if (!formData.noixuatphat || !formData.noiden) {
      alert("Vui lòng nhập đầy đủ thông tin 'Nơi xuất phát' và 'Nơi đến'.");
      return false;
    }
    if (!formData.vitri_batdau.latitude || !formData.vitri_batdau.longitude || !formData.vitri_ketthuc.latitude || !formData.vitri_ketthuc.longitude) {
      alert("Vui lòng nhập đầy đủ thông tin vị trí.");
      return false;
    }
    if (formData.quangduong <= 0) {
      alert("Quãng đường phải là số dương.");
      return false;
    }
    return true;
  };
  
  return (
    <div className="flex h-screen">
  <Sidebar />
  <div className="flex-1 flex flex-col">
    <Header />
    <div className="p-4 bg-gray-100 overflow-y-auto">
      {/* Toggle Button */}
      <button
        onClick={toggleForm}
        className="bg-green-600 text-white px-4 py-2 rounded-lg mb-6 hover:bg-green-700 transition duration-200 flex items-center space-x-2"
      >
        <i className={`fas ${showForm ? 'fa-times' : 'fa-plus'}`}></i>
        <span>{showForm ? 'Tắt form' : 'Tạo mới tuyến đường'}</span>
      </button>

      {/* Popup Form */}
      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          {formError && <div className="text-red-500 text-sm">{formError}</div>}
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 className="text-xl mb-4 text-gray-700">
              {selectedRoute ? 'Chỉnh sửa tuyến đường' : 'Thêm tuyến đường mới'}
            </h2>
            <div className="space-y-3">
              <input
                type="text"
                name="noixuatphat"
                value={formData.noixuatphat}
                onChange={handleChange}
                placeholder="Nơi xuất phát"
                className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
              <input
                type="text"
                name="noiden"
                value={formData.noiden}
                onChange={handleChange}
                placeholder="Nơi đến"
                className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
           <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
  {/* Vị trí bắt đầu - Kinh độ */}
  <div className="flex flex-col">
    <label className="mb-2 text-sm font-medium text-gray-700">Vị trí bắt đầu - Kinh độ</label>
    <input
      type="number"
      name="vitri_batdau_latitude"
      value={formData.vitri_batdau?.latitude || ''}
      onChange={(e) => {
        const value = e.target.value;
        if (value >= 0 || value === '') { // Chỉ cho phép giá trị >= 0
          setFormData((prev) => ({
            ...prev,
            vitri_batdau: {
              ...prev.vitri_batdau,
              latitude: value,
            },
          }));
        }
      }}
      className="p-2 border rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
    />
  </div>

  {/* Vị trí bắt đầu - Vĩ độ */}
  <div className="flex flex-col">
    <label className="mb-2 text-sm font-medium text-gray-700">Vị trí bắt đầu - Vĩ độ</label>
    <input
      type="number"
      name="vitri_batdau_longitude"
      value={formData.vitri_batdau?.longitude || ''}
      onChange={(e) => {
        const value = e.target.value;
        if (value >= 0 || value === '') { // Chỉ cho phép giá trị >= 0
          setFormData((prev) => ({
            ...prev,
            vitri_batdau: {
              ...prev.vitri_batdau,
              longitude: value,
            },
          }));
        }
      }}
      className="p-2 border rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
    />
  </div>

  {/* Vị trí kết thúc - Kinh độ */}
  <div className="flex flex-col">
    <label className="mb-2 text-sm font-medium text-gray-700">Vị trí kết thúc - Kinh độ</label>
    <input
      type="number"
      name="vitri_ketthuc_latitude"
      value={formData.vitri_ketthuc?.latitude || ''}
      onChange={(e) => {
        const value = e.target.value;
        if (value >= 0 || value === '') { // Chỉ cho phép giá trị >= 0
          setFormData((prev) => ({
            ...prev,
            vitri_ketthuc: {
              ...prev.vitri_ketthuc,
              latitude: value,
            },
          }));
        }
      }}
      className="p-2 border rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
    />
  </div>

  {/* Vị trí kết thúc - Vĩ độ */}
  <div className="flex flex-col">
    <label className="mb-2 text-sm font-medium text-gray-700">Vị trí kết thúc - Vĩ độ</label>
    <input
      type="number"
      name="vitri_ketthuc_longitude"
      value={formData.vitri_ketthuc?.longitude || ''}
      onChange={(e) => {
        const value = e.target.value;
        if (value >= 0 || value === '') { // Chỉ cho phép giá trị >= 0
          setFormData((prev) => ({
            ...prev,
            vitri_ketthuc: {
              ...prev.vitri_ketthuc,
              longitude: value,
            },
          }));
        }
      }}
      className="p-2 border rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
    />
  </div>
</div>


              <input
                type="number"
                name="quangduong"
                value={formData.quangduong}
                onChange={handleChange}
                min={0}
                placeholder="Quãng đường (km)"
                className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
              <div className="flex items-center space-x-6">
                <label className="flex items-center space-x-2">
                  <span className="text-gray-700 font-medium">Hiện</span>
                  <input
                    type="checkbox"
                    name="anhien"
                    checked={formData.anhien === 1}
                    onChange={() => setFormData({ ...formData, anhien: 1 })}
                    className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded"
                  />
                </label>
                <label className="flex items-center space-x-2">
                  <span className="text-gray-700 font-medium">Ẩn</span>
                  <input
                    type="checkbox"
                    name="anhien"
                    checked={formData.anhien === 0}
                    onChange={() => setFormData({ ...formData, anhien: 0 })}
                    className="form-checkbox h-5 w-5 text-red-600 border-gray-300 rounded"
                  />
                </label>
              </div>
              <div className="flex justify-between">
                <button
                  onClick={selectedRoute ? handleEditRoute : handleAddRoute}
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
                >
                  {selectedRoute ? 'Cập nhật' : 'Thêm'}
                </button>
                <button
                  onClick={toggleForm}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-200"
                >
                  Đóng
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Routes Table */}
      {isLoading ? (
        <div className="flex items-center justify-center h-screen space-x-2">
          <div className="animate-spin border-t-4 border-green-700 border-solid rounded-full w-8 h-8"></div>
          <p className="text-xl text-gray-700 font-semibold">Đang tải...</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto bg-white shadow-lg rounded-lg">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="px-4 py-2 text-left">Nơi xuất phát</th>
                <th className="px-4 py-2 text-left">Nơi đến</th>
                <th className="px-4 py-2 text-left">Điểm bắt đầu</th>
                <th className="px-4 py-2 text-left">Điểm kết thúc</th>
                <th className="px-4 py-2 text-left">Quãng đường</th>
                <th className="px-4 py-2">Hành động</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {routes.map((route) => (
                <tr key={route.id} className="border-b hover:bg-gray-50">
                  <td className="px-4 py-3">{route.noixuatphat}</td>
                  <td className="px-4 py-3">{route.noiden}</td>
                  <td className="px-4 py-3">
                  {route.vitri_batdau.latitude}, {route.vitri_batdau.longitude}
                </td>
                <td className="px-4 py-3">
                  {route.vitri_ketthuc.latitude}, {route.vitri_ketthuc.longitude}
                </td>
                  <td className="px-4 py-3">{route.quangduong} km</td>
                  <td className="px-4 py-3 flex justify-center space-x-3">
                    <button
                      className="bg-yellow-500 text-white px-3 py-1 rounded-lg hover:bg-yellow-600 transition duration-200 flex items-center justify-center"
                      onClick={() => {
                        setSelectedRoute(route);
                        setFormData({
                          noixuatphat: route.noixuatphat,
                          noiden: route.noiden,
                          vitri_batdau: route.vitri_batdau || { latitude: '', longitude: '' },
                          vitri_ketthuc: route.vitri_ketthuc || { latitude: '', longitude: '' },
                          quangduong: route.quangduong,
                          anhien: route.anhien,
                        });
                        setShowForm(true);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                    <button
                      className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200 flex items-center justify-center"
                      onClick={() => handleDeleteRoute(route.id)}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-center space-x-4 mt-4">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 transition disabled:bg-gray-200"
            >
              Trang trước
            </button>
            <span className="px-4 py-2">Trang {currentPage} / {totalPages}</span>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 transition disabled:bg-gray-200"
            >
              Trang sau
            </button>
          </div>
        </div>
      )}

      {/* Deleted Routes Section */}
      <div className="p-4 bg-gray-100">
        <h1 className="text-2xl font-semibold text-gray-700 mb-4">Danh sách tuyến đường đã xóa</h1>
        {isLoading ? (
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-blue-500"></div>
          </div>
        ) : deletedRoutes.length === 0 ? (
          <p className="text-gray-500">Không có dữ liệu</p>
        ) : (
          <table className="min-w-full table-auto bg-white shadow-lg rounded-lg">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="px-4 py-2 text-left">Nơi xuất phát</th>
                <th className="px-4 py-2 text-left">Nơi đến</th>
                <th className="px-4 py-2 text-left">Điểm đầu</th>
                <th className="px-4 py-2 text-left">Điểm kết thúc</th>
                <th className="px-4 py-2 text-left">Quãng đường</th>
                <th className="px-4 py-2">Hành động</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {deletedRoutes.map((route) => (
                <tr key={route.id} className="border-b hover:bg-gray-50">
                  <td className="px-4 py-3">{route.noixuatphat}</td>
                  <td className="px-4 py-3">{route.noiden}</td>
                  <td className="px-4 py-3">{route.bendi}</td>
                  <td className="px-4 py-3">{route.benden}</td>
                  <td className="px-4 py-3">{route.quangduong} km</td>
                  <td className="px-4 py-3 flex justify-center space-x-3">
                  <button
                        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 flex items-center justify-center"
                        onClick={() => handleRestore(route.id)}
                      >
                        <i className="fas fa-undo"></i>
                      </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  </div>
</div>

  );
};

export default RouteManagement;
