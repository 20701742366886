import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";

const CustomerSupport = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Thêm trạng thái để kiểm tra đăng nhập
  const navigate = useNavigate(); // Khai báo hook useNavigate

  // Kiểm tra trạng thái đăng nhập khi component load
  useEffect(() => {
    const token = localStorage.getItem("access_token"); 
    if (token) {
      setIsLoggedIn(true); // Nếu có token, người dùng đã đăng nhập
    } else {
      setIsLoggedIn(false); // Nếu không có token, người dùng chưa đăng nhập
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isLoggedIn) {
      alert("Vui lòng đăng nhập trước khi gửi câu hỏi.");
      return;
    }

    const contactData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    try {
      const token = localStorage.getItem("access_token"); 
      const response = await fetch("http://localhost:8000/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(contactData),
        
      });

      if (response.status === 201) {
        console.log("Thông tin liên hệ đã được gửi thành công", contactData);
        setSubmitted(true);
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        const errorData = await response.json();
        console.error("Lỗi khi gửi thông tin liên hệ:", errorData.message);
        alert(
          errorData.message || "Gửi câu hỏi thất bại, vui lòng thử lại sau."
        );
      }
    } catch (error) {
      console.error("Lỗi kết nối:", error);
      alert("Có lỗi xảy ra khi gửi câu hỏi.");
    }
  };

  return (
    <>
    <Header />
      <div className="bg-white p-8 rounded-lg shadow-md mt-10 max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-green-600 mb-6">
          Hỗ trợ khách hàng
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Form Support */}
          <div>
            {submitted ? (
              <div className="text-center">
                <h3 className="text-lg font-semibold text-gray-800">
                  Cảm ơn bạn!
                </h3>
                <p className="text-gray-600">
                  Câu hỏi của bạn đã được gửi. Chúng tôi sẽ phản hồi sớm nhất có
                  thể.
                </p>
              </div>
            ) : !isLoggedIn ? (
              <div className="text-center text-red-600">
                <h3 className="text-lg font-semibold">Vui lòng đăng nhập để gửi câu hỏi.</h3>
                <button
                  onClick={() => navigate("/login")} // Sử dụng navigate thay vì history.push
                  className="bg-green-600 text-white py-2 px-4 rounded-lg mt-4"
                >
                  Đăng nhập
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor="name">
                    Họ tên
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Nhập tên bạn ở đây..."
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Nhập email của bạn ở đây..."
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor="phone">
                    Số điện thoại
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Nhập số điện thoại của bạn..."
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="message"
                  >
                    Câu hỏi của bạn
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Câu hỏi bạn cần hỗ trợ..."
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-green-600 text-white py-2 px-4 rounded-lg w-full hover:bg-green-700"
                >
                  Gửi câu hỏi
                </button>
              </form>
            )}
          </div>

          {/* Contact Information */}
          <div className="flex flex-col justify-center items-center">
            <div className="pl-12">
              <img
                src="/images/1766-scaled.jpg" // Thay thế với đường dẫn hình ảnh thực tế
                alt="Hỗ trợ khách hàng"
                className="w-full h-50"
              />
            </div>
            <h3 className="text-lg font-semibold text-gray-800 mb-8">
              Thông tin liên hệ
            </h3>
            <p className="text-gray-600 mb-2">
              <b>Điện thoại:</b>{" "}
              <a href="tel:+84123456789" className="text-green-600">
                +84 123 456 789
              </a>
            </p>
            <p className="text-gray-600 mb-2">
              <b>Email:</b>{" "}
              <a href="mailto:support@busfree.com" className="text-green-600">
                support@busfree.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <br></br>
      <Footer />
    </>
  );
};

export default CustomerSupport;
