// Import necessary modules
import React from "react";

const BannerHome = () => {
  return (
    <div className="relative w-full h-[500px] overflow-hidden">
      {/* Background Image */}
      <img
        src="https://vinbus.vn/storage/photos/shares/Trang-chu/Ea2hPj7XGW-1.jpg" // Replace with the actual BusFree-related image URL
        alt="BusFree Banner"
        className="absolute inset-0 w-full h-full object-cover opacity-90"
      />

      {/* Overlay Content */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-black text-center px-6">
        <h1 className="text-4xl font-bold md:text-5xl">
          Giới thiệu <span className="text-green-800">BusFree</span>
        </h1>
        <p className="mt-4 text-lg md:text-xl max-w-2xl">
          Trải nghiệm dịch vụ đặt vé xe buýt hiện đại, nhanh chóng và tiện lợi mọi lúc mọi nơi.
          BusFree là dịch vụ xe buýt trực tuyến, cung cấp cho bạn những trải nghiệm di chuyển an toàn, tiện lợi và tiết kiệm.
            Chúng tôi cam kết đem lại dịch vụ tốt nhất với mạng lưới xe buýt rộng khắp và dễ dàng đặt vé chỉ bằng vài cú click
        </p>
        <button class="w-[150px] bg-black h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer relative overflow-hidden transition-all duration-500 ease-in-out shadow-md hover:scale-105 hover:shadow-lg before:absolute before:top-0 before:-left-full before:w-full before:h-full before:bg-gradient-to-r before:from-[#009b49] before:to-[rgb(105,184,141)] before:transition-all before:duration-500 before:ease-in-out before:z-[-1] before:rounded-xl hover:before:left-0 text-[#fff]">
  Khám phá
</button>

      </div>

      {/* Decorative Overlay */}
      <div className="absolute inset-0"></div>
    </div>
  );
};

export default BannerHome;
