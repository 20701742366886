import React, { useState, useEffect } from "react";
import axios from "axios";
import EmojiPicker from "emoji-picker-react"; // Thư viện emoji picker
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faTimes,
  faPaperPlane,
  faSmile,
} from "@fortawesome/free-solid-svg-icons";

const ChatBot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false); // Trạng thái mở/đóng chat
  const [showGreeting, setShowGreeting] = useState(false); // Trạng thái hiển thị đoạn text
  const [inputMessage, setInputMessage] = useState(""); // Tin nhắn người dùng nhập
  const [messages, setMessages] = useState([]); // Lịch sử tin nhắn
  const [isLoading, setIsLoading] = useState(false); // Trạng thái loading khi gọi API
  const [userName, setUserName] = useState("Bạn"); // Tên người dùng (mặc định là "Bạn")
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Hiển thị bảng emoji

  const API_KEY =
    "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";
  const botName = "Đinh Ngọc Hiếu CSKH"; // Tên chatbot

  // Lấy tên người dùng từ localStorage
  useEffect(() => {
    const storedUserName = localStorage.getItem("user_name");
    if (storedUserName) {
      setUserName(storedUserName);
    }
  }, []);

  // Hiển thị text chào mừng
  useEffect(() => {
    setShowGreeting(true);
  }, []);

  // Toggle mở/đóng cửa sổ chat
  const toggleChat = () => {
    if (!isChatOpen) {
      const hasGreetingMessage = messages.some((msg) =>
        msg.text.includes("Tôi có thể giúp gì cho bạn hôm nay?")
      );
      if (!hasGreetingMessage) {
        setMessages([
          ...messages,
          {
            sender: "bot",
            text: `Xin chào ${userName}! Tôi có thể giúp gì cho bạn hôm nay? 😊`,
          },
        ]);
      }
    }
    setIsChatOpen(!isChatOpen);
  };

  // Thêm emoji vào tin nhắn
  // Thêm emoji vào tin nhắn
  const onEmojiClick = (emojiData) => {
    setInputMessage((prev) => prev + emojiData.emoji); // Lấy emoji và thêm vào ô nhập tin nhắn
    setShowEmojiPicker(false); // Ẩn bảng emoji sau khi chọn
  };

  // Gửi tin nhắn đến OpenAI API
  const sendMessage = async () => {
    if (inputMessage.trim() === "") return;

    const userMessage = { sender: "user", text: inputMessage };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setInputMessage("");
    setIsLoading(true);

    try {
      const systemPrompt = {
        role: "system",
        content:
          "Bạn là chatbot của trang web BusFree. BusFree là trang web cung cấp thông tin về các tuyến xe buýt, lộ trình, thời gian xe chạy, tin tức giao thông và hỗ trợ khách hàng.",
      };

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-mini",
          messages: [
            systemPrompt,
            ...updatedMessages.map((msg) => ({
              role: msg.sender === "user" ? "user" : "assistant",
              content: msg.text,
            })),
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );

      const botMessage = {
        sender: "bot",
        text: response.data.choices[0].message.content.trim(),
      };

      setMessages([...updatedMessages, botMessage]);
    } catch (error) {
      console.error("Error fetching response:", error);
      const errorMessage = {
        sender: "bot",
        text: "Xin lỗi, có lỗi xảy ra. Vui lòng thử lại sau!",
      };
      setMessages([...updatedMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* Text chào mừng */}
      {showGreeting && !isChatOpen && (
        <div className="fixed bottom-28 right-6 bg-white text-gray-800 p-3 rounded-lg shadow-lg animate-fade-in">
          <span className="text-sm font-semibold">
            Chào {userName}, ngày mới vui vẻ! 😊
          </span>
        </div>
      )}

      {/* Nút mở chatbot */}
      <button
        onClick={toggleChat}
        className="fixed bottom-8 right-8 w-16 h-16 bg-gradient-to-r from-red-500 to-green-500 text-white rounded-full shadow-lg flex items-center justify-center hover:bg-green-700 transition-all duration-300 z-50"
      >
        <FontAwesomeIcon icon={isChatOpen ? faTimes : faComment} size="lg" />
      </button>

      {/* Cửa sổ chat */}
      {isChatOpen && (
        <div className="fixed bottom-24 right-8 w-96 h-[500px] bg-white border border-gray-300 rounded-lg shadow-lg z-40 flex flex-col">
          {/* Header */}
          <div className="bg-gradient-to-r from-red-500 to-green-500 text-white p-4 rounded-t-lg flex justify-between items-center">
            <h3 className="text-lg font-semibold">Chăm sóc khách hàng</h3>
            <div className="flex space-x-2">
              {/* Nút Xóa lịch sử */}
              <button
                onClick={() => setMessages([])}
                className="text-sm bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                Xóa lịch sử
              </button>
              <button onClick={toggleChat}>
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </button>
            </div>
          </div>

          {/* Nội dung chat */}
          <div className="flex-1 p-4 overflow-y-auto space-y-4">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`flex flex-col ${
                  msg.sender === "user" ? "items-end" : "items-start"
                }`}
              >
                <span className="text-sm font-semibold mb-1 text-gray-600">
                  {msg.sender === "user" ? userName : botName}
                </span>
                <div
                  className={`p-2 rounded max-w-[80%] ${
                    msg.sender === "user"
                      ? "bg-green-100 text-right"
                      : "bg-gray-200 text-left"
                  }`}
                >
                  {msg.text}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="text-gray-500 text-center my-2">
                Đang trả lời...
              </div>
            )}
          </div>

          {/* Input chat */}
          <div className="p-4 border-t flex items-center relative space-x-1">
            {/* Emoji Picker */}
            {showEmojiPicker && (
              <div className="absolute bottom-14 left-2 z-50 bg-white shadow-md rounded-lg">
                <EmojiPicker onEmojiClick={onEmojiClick} />
              </div>
            )}

            {/* Nút mở Emoji Picker */}
            <button
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="text-gray-500 hover:text-gray-700 mr-2"
            >
              <FontAwesomeIcon icon={faSmile} size="lg" />
            </button>

            {/* Ô nhập */}
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") sendMessage();
              }}
              placeholder="Nhập tin nhắn..."
              className="w-full border border-gray-300 p-2 rounded-l-lg focus:outline-none text-gray-700"
            />

            {/* Nút gửi */}
            <button
              onClick={sendMessage}
              className="bg-green-600 text-white px-4 flex items-center justify-center rounded-r-lg hover:bg-green-700 transition-all h-10"
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBot;
